import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Hipa.css'; // Import the CSS file
import hipa from '../assets/New folder/hipa.PNG'
import img1 from '../assets/hipa/img1.jpg'
import img2 from '../assets/hipa/img2.jpeg'
import img3 from '../assets/hipa/img3.jpg'
import img4 from '../assets/hipa/img4.jpg'
import './style.css'
export default function Hipa() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <>

      {/* <div className="hipa-container">
        <div className="bg-white text-gray-700 p-12 lg:p-20  hover-effect">
          <img src={hipa} className="m-auto  mb-5" data-aos="zoom-in" />
          <p className="font-bold text-xl text-center mb-5" data-aos="fade-up">HIPAA Compliance</p>
          <div className="desc text-center" data-aos="fade-up">
            HIPAA compliance is crucial for securing patients' health information in medical billing. Understanding the standards, doing risk assessments, installing safeguards, employing BAAs, gaining patient consent, giving training and audits, planning for incidents, remaining updated, and seeking professional help are all essential precautions. Continuous proactive approaches, monitoring, and prioritization of data security are critical. Expert assistance can help assure compliance.
          </div>
          <div className="arrow text-center">
            <span className="icon" data-aos="zoom-in">
              <i className="fas fa-arrow-right"></i>
            </span>
          </div>
        </div>
      </div> */}

      {/* //  */}
      <div class="w-full sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl m-auto  p-8 pl-16 pr-16 ">
        <div class="max-w-md mx-auto text-center">
        <h2 class="text-4xl mb-4 font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">HIPAA Compliance</h2>
          {/* <h2 class="text-3xl font-bold text-gray-950 mb-5 mt-2">We simplify the process</h2> */}
          <p class="text-base font-semibold text-gray-500">All patient data is handled with the utmost confidentiality and in strict compliance with HIPAA regulations to ensure privacy and security.</p>
        </div>

        <div class="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6 mt-12">
          <div data-aos="zoom-in">
            <div class="rounded-xl bg-white overflow-hidden transition-transform hover:scale-105 shadow-lg">
              <img src={img1} alt="" />
              <div class="p-4">
                <h2 class="text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Data Privacy</h2>
                <p class="text-sm font-medium text-gray-500 mt-4">Your personal health data is safeguarded with stringent privacy measures in compliance with HIPAA standards.</p>
              </div>
            </div>
          </div>

          <div class="md:mt-20" data-aos="zoom-in" >
            <div class="rounded-xl bg-white overflow-hidden transition-transform hover:scale-105 shadow-lg ">
              <img src={img2} alt="" />
              <div class="p-4">
                <h2 class="text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Protected Health Information</h2>
                <p class="text-sm font-medium text-gray-500 mt-4">Protected Health Information (PHI) refers to any personal medical data that must be securely protected according to HIPAA regulations.</p>
              </div>
            </div>
          </div>

          <div data-aos="zoom-in">
            <div class="rounded-xl bg-white overflow-hidden transition-transform hover:scale-105 shadow-lg">
              <img src={img3} alt="" />
              <div class="p-4">
                <h2 class="text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Data Breach Prevention</h2>
                <p class="text-sm font-medium text-gray-500 mt-4">Implement strict data access controls and encryption to prevent unauthorized breaches.</p>
              </div>
            </div>
          </div>

          <div class="md:mt-20" data-aos="zoom-in">
            <div class="rounded-xl bg-white overflow-hidden transition-transform hover:scale-105  shadow-lg">
              <img src={img4} alt="" style={{width:'100%'}} />
              <div class="p-4">
                <h2 class="text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Electronic Health Records</h2>
                <p class="text-sm font-medium text-gray-500 mt-4">Securely manage and protect patient data with HIPAA-compliant Electronic Health Records.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

