import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import hepatology from '../assets/subspecialities/HEPATOLOGY.jpg'
import cardiology from '../assets/subspecialities/cardiology.jpg'
import urology from '../assets/subspecialities/Urology.jpg'
import oncology from '../assets/subspecialities/Oncology.jpg'
import nephrology from '../assets/subspecialities/Nephrology.jpg'
import podiatry from '../assets/subspecialities/Podiatry.jpg'
import psychiatric from '../assets/subspecialities/Psychiatric.jpg'
import psychology from '../assets/subspecialities/Psychology.webp'
import clinical from '../assets/subspecialities/Clinical.jpg'
import hematology from '../assets/subspecialities/Hematology.jpg'
import orthopedics from '../assets/subspecialities/Orthopedics.jpg'
import rheumatology from '../assets/subspecialities/Rheumatology.jpg'
import dermatology from '../assets/subspecialities/Dermatology.jpg'
import gynecology from '../assets/subspecialities/Gynecology.jpg'
import pulmonology from '../assets/subspecialities/Pulmonology.jpg'
import ThoracicSurgery from '../assets/subspecialities/Thoracic-Surgery.jpg'
import PhysicalTherapy from '../assets/subspecialities/Physical-Therapy.jpg'
import PathologyLab from '../assets/subspecialities/Pathology-Lab.jpg'
import PainManagement from '../assets/subspecialities/Pain-Management.png'
import Neurosurgery from '../assets/subspecialities/Neurosurgery.jpg'
import Geriatrics from '../assets/subspecialities/Geriatrics.jpg'
import MolecularLab from '../assets/subspecialities/Molecular-Lab.jpg'
import Pediatric from '../assets/subspecialities/Pediatric.jpg'
export default function Ourspecialities() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);


  const specialities = [
    {
      title: 'HEPATOLOGY BILLING SERVICES',
      content: 'Increasingly, physicians are expressing frustration with the overwhelming administrative workload that not only adds to their stress but also detracts from the quality time they can spend with their patients. Hepatology medical billing is particularly complex and demands significant time and effort to navigate the intricate paperwork, yet it remains crucial to the financial health of the practice, as it directly impacts the reimbursement for patient care services.',
      img: hepatology,
    },
    {
      title: 'CARDIOLOGY MEDICAL BILLING',
      content: 'Recent breakthroughs in cardiology have significantly improved patient outcomes, but they have also introduced more intricate coding and billing challenges. At Right Medical Billing, we focus on navigating these complexities for our clients, ensuring they optimize revenue from government incentives and remain compliant with the latest regulations. Our services encompass daily billing management, swift resolution of claim denials and rejections, and the alignment of charting and coding with the annual payer adjustments to maximize efficiency and accuracy.',
      img: cardiology,
    },
    {
      title: 'UROLOGY BILLING SERVICES',
      content: 'We deliver comprehensive and patient-centered medical billing solutions tailored specifically for urology providers. At ThriveMed RCM, our full-service billing approach is designed to streamline operations, minimize denials, and enhance cash flow. From minor in-office procedures to complex surgeries requiring extended hospital stays and follow-up care, we ensure that every aspect of coding is meticulously handled, alleviating the frustration that often comes with managing these intricate processes.',
      img: urology,
    },
    {
      title: 'ONCOLOGY MEDICAL BILLING SERVICES',
      content: 'ThriveMed RCM offers expert oncology billing services designed to ensure claims are accurately submitted and fully compliant with government regulations, maximizing reimbursements for our clients. Our comprehensive suite of medical revenue cycle management (RCM) services streamlines the entire billing process. By outsourcing oncology RCM to us, healthcare providers can boost their revenue while dedicating more time to delivering quality patient care.',
      img: oncology,
    },
    {
      title: 'NEPHROLOGY BILLING SERVICES',
      content: 'At our nephrology billing firm, our team of experts ensures that coding and auditing services align with government incentives to maximize your revenue. We meticulously handle every detail to achieve prompt, accurate, and real-time claim reimbursements, enhancing your practices financial performance. Accurate medical coding and billing are crucial as they directly impact your practice’s payment for services rendered, making precision in these processes essential.',
      img: nephrology,
    },
    {
      title: 'PODIATRY BILLING SERVICES',
      content: 'Our mission is to drive innovation and efficiency in the revenue cycle, boosting our clients financial outcomes by minimizing errors. With varying rules for medically necessary procedures and different insurer requirements, managing claims can be complex. As the population ages, the prevalence of foot and ankle issues increases, leading to more Medicare patients and additional coding challenges. We streamline this process, ensuring compliance and maximizing reimbursement across diverse payer systems.',
      img: podiatry,
    },
    {
      title: 'PSYCHIATRIC BILLING SERVICES',
      content: 'Our goal is to enhance insurance and patient collections, allowing our clients to prioritize patient care and expand their practices. By adopting a comprehensive revenue cycle management strategy, we handle everything from credentialing and daily claims processing to annual audits, safeguarding your revenue as you concentrate on delivering excellent care. Outsourcing RCM and associated administrative tasks, including payer credentialing and compliance checks, has consistently proven to be an effective strategy for healthcare providers and institutions nationwide.',
      img: psychiatric,
    },
    {
      title: 'PSYCHOLOGY BILLING SERVICES',
      content: 'ThriveMed RCM specializes in psychotherapy billing services, working closely with you to streamline your practice, enhance cash flow, and ensure that every session is accurately billed. Our comprehensive revenue cycle management covers everything from credentialing to annual evaluations, allowing you to concentrate on patient care while we safeguard your revenue. Recognizing that psychology has unique billing requirements compared to other medical fields, we tailor our services to meet these specific needs effectively.',
      img: psychology,
    }, {
      title: 'CLINICAL LAB BILLING SERVICES',
      content: 'As a clinical laboratory, you may be feeling the pressure from heightened regulatory scrutiny, escalating operational costs, and diminishing reimbursement rates, all of which contribute to a challenging financial landscape. In this competitive environment with frequent client turnover, it’s crucial to refine your documentation and billing processes to optimize cost recovery. This task becomes even more daunting when faced with requisitions from ordering providers that contain incomplete or inaccurate information. Ensuring meticulous billing practices is essential to navigate these obstacles and maintain financial stability.',
      img: clinical,
    }, {
      title: 'HEMATOLOGY BILLING SERVICES',
      content: 'We offer comprehensive management for your hematology practice, including audit risk assessment, EHR implementation, and revenue optimization. Despite efforts to simplify medical coding, it remains a complex and often stressful aspect of running a medical practice. Our specialized hematology billing services are designed to alleviate this burden, enhance revenue, and streamline your operations. No field of medicine is immune to the intricacies of billing, but with our expertise, you can focus more on patient care and less on administrative challenges.',
      img: hematology,
    }, {
      title: 'ORTHOPEDICS BILLING SERVICES',
      content: 'We enhance your practices claim revenue by boosting accuracy and efficiency, allowing your team to concentrate on delivering exceptional patient care. Billing complexities arise when surgeons from different institutions collaborate on a procedure, necessitating meticulous coding to prevent issues with duplicate or redundant charges. Our advanced billing solutions include cutting-edge software designed to streamline the entire process and ensure seamless, accurate billing, ultimately supporting your practice’s financial health.',
      img: orthopedics,
    }, {
      title: 'RHEUMATOLOGY BILLING SERVICES',
      content: 'We stay ahead of the evolving healthcare landscape by delivering precise and timely rheumatology billing services that ensure efficient reimbursement for your practice. By offering accurate, comprehensive, and real-time billing solutions, we help reduce your expenses and streamline your operations. Every medical specialty, including rheumatology, benefits from offloading the complexities of billing and coding. Rheumatology presents unique challenges, including intricate diagnostic codes and frequent changes in treatment protocols, making expert billing support essential for optimal financial performance.',
      img: rheumatology,
    }, {
      title: 'DERMATOLOGY MEDICAL BILLING SERVICES',
      content: 'Medical billing and coding pose significant challenges for any practice, and dermatology is no exception. ThriveMed RCM specializes in dermatology billing services to enhance revenue and alleviate the complexities associated with medical billing. Dermatologys unique challenges, such as diverse treatment codes and intricate documentation requirements, often lead to billing errors and delayed payments. By outsourcing your dermatology medical billing to ThriveMed RCM, you not only increase your payments but also free up your staff to focus on patient care and practice growth.',
      img: dermatology,
    }, {
      title: 'GYNECOLOGY BILLING SERVICES',
      content: 'We streamline collection payments and verify insurance eligibility at the point of service to ensure optimal revenue. Our billing experts are dedicated to enhancing patient collections, ensuring accurate markups, and boosting overall productivity. Given the complexity and time demands of billing and coding, entrusting these tasks to certified, experienced professionals is far more efficient than having your medical office staff handle them. Here are some key reasons why outsourcing gynecology billing has consistently proven to be a successful strategy.',
      img: gynecology,
    }, {
      title: 'PULMONOLOGY BILLING SERVICES',
      content: 'We specialize in pulmonology billing services to support your practice in delivering exceptional patient care, boosting productivity, and enhancing cash flow. Our goal is to optimize your operations and provide valuable insights into generating new leads. Recent advancements in pulmonology have improved treatment outcomes but also introduced more intricate coding and billing challenges. Our services are designed to navigate these complexities and ensure your practice remains compliant and financially efficient.',
      img: pulmonology,
    }, {
      title: 'THORACIC SURGERY BILLING SERVICES',
      content: 'Our comprehensive services cover all aspects needed to optimize your revenue. We manage the intricate billing processes to ensure your operations run smoothly. Our billing specialists also address credentialing and privileging requirements to overcome any obstacles effectively. Medical coding and billing are inherently complex, and specialized fields like thoracic surgery pose additional challenges due to the extensive range of procedures and variations. Ensuring accurate coding and adherence to insurance claim requirements can be daunting for office staff, but our expertise simplifies this process and maximizes your practices financial performance.',
      img: ThoracicSurgery,
    }, {
      title: 'PHYSICAL THERAPY  BILLING SERVICES',
      content: 'ThriveMed RCM’s advanced physical therapy medical billing and coding services are designed to handle annual code updates and produce accurate, error-free claims that maximize reimbursement. While all conditions treated are legitimate, payers often have stringent and complex rules regarding coverage and conditions, which can be challenging for medical staff to navigate. Our billing specialists expertly manage every facet of the revenue cycle for physical therapists, ensuring seamless operations and optimal financial outcomes for your practice.',
      img: PhysicalTherapy,
    },
    {
      title: 'PATHOLOGY BILLING SERVICES',
      content: 'For an efficient pathology billing process, its crucial to have a team with in-depth knowledge and practical experience in claims recovery. ThriveMed RCM offers comprehensive, user-friendly billing services that enhance your financial performance and growth. Pathology practices, groups, and labs face unique billing challenges due to their specific requirements. To effectively submit and collect claims, our billing experts possess specialized knowledge in pathology sub-specialties and stay current with billing guidelines and CPT code updates, ensuring accurate and timely reimbursements.',
      img: PathologyLab,
    },
    {
      title: 'PAIN MANAGEMENT BILLING',
      content: 'As experts in medical billing and coding, ThriveMed RCM ensures that pain management billing is handled with precision and speed, adapting to annual code updates to streamline payment processes. Pain management specialists face the challenge of treating patients with often non-visible conditions, making accurate billing essential. With heightened scrutiny and regulations around pain medications and their use, our team is adept at navigating these complexities to secure timely reimbursements. We help alleviate the burden of increased scrutiny and regulatory demands, allowing practitioners to focus on patient care.',
      img: PainManagement,
    },
    {
      title: 'NEUROSURGERY BILLING SERVICES',
      content: 'Neurosurgery billing has seen more changes and complexities than many other healthcare services, making accurate billing crucial for revenue optimization. At ThriveMed RCM, our specialized team ensures that your practice remains compliant and maximizes revenue by preventing billing errors. We understand that frequent modifications to medical codes can lead to mistakes, especially when billers become too accustomed to routine procedures and overlook critical details. Our experts focus on the nuances of neurosurgery billing to ensure precision and efficiency, mitigating the risk of errors that often stem from overwhelming paperwork and regulatory updates.',
      img: Neurosurgery,
    },
    {
      title: 'GERIATRICS BILLING SERVICES',
      content: 'At ThriveMed RCM, our sole focus on coding and billing allows us to stay at the forefront of government updates and incentives, ensuring we optimize your revenue streams. We efficiently manage daily billing tasks, swiftly address claim denials and rejections, and handle all aspects of charting, coding compliance, and payer adjustments. Outsourcing your geriatrics medical billing to us alleviates the burden of paperwork and enhances your practice’s financial health. Our team is rigorously trained in HIPAA security and privacy requirements, guaranteeing that your patients information is meticulously protected.',
      img: Geriatrics,
    },
    {
      title: 'MOLECULAR LABORATORY BILLING',
      content: 'ThriveMed RCM offers expert molecular medical billing services designed to ensure strict regulatory compliance and optimize your revenue. Our streamlined billing process is the result of years of refinement, making it exceptionally efficient. We handle denials and rejections with precision, meticulously tracking each claim to ensure prompt payment. Additionally, we manage the complexities of less common billing aspects, such as annual payer adjustments and paybacks. Our experienced team knows exactly how to appeal denied or rejected claims, effectively communicating the medical necessity to insurers to enhance your chances of successful reimbursement.',
      img: MolecularLab,
    },
    {
      title: 'PEDIATRIC MEDICAL BILLING SERVICES',
      content: 'Pediatric practices face unique challenges that demand focused, attentive care for young patients and their families. Outsourcing pediatric billing services can significantly alleviate the burden on in-office staff, allowing them to concentrate on delivering the highest quality care. Managing a pediatric office involves addressing the needs of children who are often more susceptible to illnesses, handling siblings, and ensuring minimal wait times—all of which require meticulous attention. By outsourcing medical coding and billing, practices can streamline administrative tasks, keep up with payer changes, and ensure that their staff remains dedicated to providing exceptional care without the added stress of billing complexities.',
      img: Pediatric,
    },

  ]
  return (


    <div class="flex flex-col m-auto  justify-center sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl xl:pl-0 xl:pr-0 pl-10 pr-10 lg:mt-12 mt-36 pt-10 ">
      {
        specialities.map((speciality) => {
          return (
            <div className='flex mb-3 flex-col lg:flex-row justify-center align-middle lg:ml-8 lg:mr-8 sm:mr-4 sm:ml-4 lg:pl-12 lg:pr-12 pl-4 pr-4 pt-4 pb-4'>
              <div className='content lg:mr-44   flex flex-col justify-center'>
                <p className=' bg-gradient-to-r from-green-500 to-blue-700 bg-clip-text text-transparent font-bold text-4xl  mb-4 text-center xl:text-4xl lg:text-start uppercase' data-aos="fade-down">{speciality.title}</p>
                <p className='text-gray-500 ' data-aos="fade-up">{speciality.content}</p>
              </div>
              <img src={speciality.img} data-aos="zoom-in" alt="hepatology" className='lg:w-64 h-64  m-auto mt-4 w-full rounded-lg' />
            </div>
          )
        })
      }


    </div>

  )
}
