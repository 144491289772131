import React from 'react'
import { useNavigate} from 'react-router-dom'
import sp1 from '../assets/specialities/sp1.PNG'
import sp2 from '../assets/specialities/sp2.PNG'
import sp3 from '../assets/specialities/sp3.PNG'
import sp4 from '../assets/specialities/sp4.PNG'
import sp5 from '../assets/specialities/sp5.PNG'
import sp6 from '../assets/specialities/sp6.PNG'
import sp7 from '../assets/specialities/sp7.PNG'
import sp8 from '../assets/specialities/sp8.PNG'
import sp9 from '../assets/specialities/sp9.PNG'
import sp10 from '../assets/specialities/sp10.PNG'
import sp11 from '../assets/specialities/sp11.PNG'
import sp12 from '../assets/specialities/sp12.PNG'
import sp13 from '../assets/specialities/sp13.PNG'
import sp14 from '../assets/specialities/sp14.PNG'
import sp15 from '../assets/specialities/sp15.PNG'
import sp16 from '../assets/specialities/sp16.PNG'
import sp17 from '../assets/specialities/sp17.PNG'
import sp18 from '../assets/specialities/sp18.PNG'
import sp19 from '../assets/specialities/sp19.PNG'
import sp20 from '../assets/specialities/sp20.PNG'
import sp21 from '../assets/specialities/sp21.PNG'
import sp22 from '../assets/specialities/sp22.PNG'
import sp23 from '../assets/specialities/sp23.PNG'
import sp24 from '../assets/specialities/sp24.PNG'
import sp25 from '../assets/specialities/sp25.PNG'

const speciality = [
  { sp: sp1 ,
    title:'Hepatology'
  },
  { sp: sp2,
    title:'Cardiology'
   },
  { 
    sp: sp3,
    title:'Nephrology'
   },
  { 
    sp: sp4,
    title:'Oncology'
  },
  { 
    sp: sp5,
    title:'Nephrology'
  },
  { 
    sp: sp6,
    title:'Podiatry'
  },
  { 
    sp: sp7,
    title:'Psychiatric' 
  },
  { 
    sp: sp8,
    title:'Psychology'
  },
  { 
    sp: sp9,
    title:'Clinical Lab'
  },
  { 
    sp: sp10,
    title:'Hematology'
  },
  { 
    sp: sp11,
    title:'Orthopedics'
  },
  { 
    sp: sp12,
    title:'Rheumatology'
  },
  { 
    sp: sp13,
    title:'Dermatology'
  },
  { 
    sp: sp14,
   title:'Gynecology'
  },
  { 
    sp: sp15,
   title:'Pulmonology'
  },
  { 
    sp: sp16,
    title:'Thorasic Surgery'
  },
  { 
    sp: sp17,
    title:'Physical Therapy'
  },
  { 
    sp: sp18,
   title:'Pathology Lab'
  },
  { 
    sp: sp19,
   title:'Ambulatory Surgery'
  },
  { 
    sp: sp20,
    title:'Pain Management'
  },
  { 
    sp: sp21,
    title:'Neurosurgery'
  },
  // { 
  //   sp: sp22,
  //   title:'Geriatrics' 
  // },
  { 
    sp: sp23,
    title:'Radiology'
  },
  {
     sp: sp24,
     title:'Molecular Lab'
    },
  {
     sp: sp25,
     title:'Pediatric' 
    },
]

export default function Subspeciality() {
  let navigate=useNavigate();

  const handledynamicroute=()=>{
    navigate('/Ourspecialities')
  }
  return (
    <section className="w-full py-8 bg-white sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl">
      {/* <div className="text-center mb-6 ">
       
        <h2 className="bg-gradient-to-r from-[#37b822] to-[#003680] bg-clip-text text-transparent text-6xl font-bold uppercase" data-aos="zoom-in">Our Specialities </h2>
      </div> */}
      {/* <p data-aos="fade-up" className='text-center pl-20 pr-20 lg:pl-72 lg:pr-64' data-aos-duration="1000">
        Similar to clinical coding, clinical pricing may seem complex and overwhelming, but it actually consists of just eight simple steps.
      </p> */}
      <div className='lg:p-10 p-5 lg:pl-12 lg:pr-12 flex gap-6 flex-wrap justify-center'   >
        {speciality.map((special, index) => (
          <div 
          key={index}
          className="block max-w-sm cursor-pointer p-6 bg-white border rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 active:shadow-custom-active active:scale-98 transition-transform duration-150"
          onClick={handledynamicroute}
          >
            <div className=" text-center "    >
              <img src={special.sp} alt="" className='w-36  object-cover h-32' />
              <p className='capitalize font-bold text-2xl'>{special.title}</p>
            </div>
          </div>

        ))}

      </div>
    </section>
  )
}
