import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import c1 from '../assets/medbillingswiper/1m.PNG';
import c2 from '../assets/medbillingswiper/2m.PNG';
import c3 from '../assets/medbillingswiper/3m.PNG';
import c4 from '../assets/medbillingswiper/4m.PNG';
import c5 from '../assets/medbillingswiper/5m.PNG';
export default function MBsecondswiper() {
  useEffect(() => {
    AOS.init();
  }, []);
  const logos = [
    '1. Fast Filing',
    '2. Clean Claims',
    '3. Tracking System',
    '4. Rigorous Follow-up',
    '5. A/R Management',
  ];
  return (
    <section className="bg-white">
      {/* <p className='font-bold text-4xl text-center mb-12' data-aos="fade-up" >How ThriveMed Increases Client Revenue</p> */}
      <div className="text-center mb-6 ">
        <h2 className="mb-10 m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-lg lg:text-4xl  font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">How ThriveMed Increases Client Revenue</h2>
      </div>
      <Swiper
        modules={[Autoplay]}
        spaceBetween={30}
        slidesPerView={2}
        autoplay={{ delay: 1500, disableOnInteraction: false }}
        loop={true}
        className='w-full'
        breakpoints={{
          360: { slidesPerView: 1, spaceBetween: 20 },
          375: { slidesPerView: 1, spaceBetween: 20 },
          390: { slidesPerView: 1, spaceBetween: 20 },
          412: { slidesPerView: 1, spaceBetween: 20 },
          414: { slidesPerView: 1, spaceBetween: 20 },
          430: { slidesPerView: 1, spaceBetween: 20 },
          640: { slidesPerView: 2, spaceBetween: 20 },
          768: { slidesPerView: 2, spaceBetween: 30 },
          1024: { slidesPerView: 3, spaceBetween: 30 },
          1280: { slidesPerView: 4, spaceBetween: 20 },
          1536: { slidesPerView: 4, spaceBetween: 20 },
        }}
      >
        {logos.map((logo, index) => (
          <SwiperSlide key={index} className=''>
            <div class="block m-auto   p-6 w-64  bg-white border hover:text-white hover:bg-gradient-to-r from-green-400 to-blue-400   rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 class="mb-2 text-xl uppercase font-bold tracking-tight dark:text-white text-center" data-aos="fade-up">{logo}</h5>
          </div>
              
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}
