import React, { useEffect,useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './Counter.css';
import { CountUp } from 'https://cdnjs.cloudflare.com/ajax/libs/countup.js/2.0.7/countUp.js'

export default function Projectscounter() {
  const [turnaround,setTurnaround]=useState(0);
  const [reduction,setReduction]=useState(0);
  const [revenue,setRevenue]=useState(0);
  const [ratio,setRatio]=useState(0);
  
  useEffect(() => {
    AOS.init({ duration: 1000 });
    const turn = setInterval(() => {
      setTurnaround(prev => {
        if (prev >= 7) {
          clearInterval(turn);
          return prev; 
        }
        return prev + 1;
      });
    }, 500);

    const redu = setInterval(() => {
      setReduction(prev => {
        if (prev >= 30) {
          clearInterval(redu);
          return prev; 
        }
        return prev + 1;
      });
    }, 500);

    const reve = setInterval(() => {
      setRevenue(prev => {
        if (prev >= 15) {
          clearInterval(reve);
          return prev; 
        }
        return prev + 1;
      });
    }, 500);

    const rati = setInterval(() => {
      setRatio(prev => {
        if (prev >= 90) {
          clearInterval(rati);
          return prev; 
        }
        return prev + 1;
      });
    }, 200);

    return () => clearInterval(turn,redu,reve,rati);
    }, []);


    

  return (
    // <div className="relative pl-10 pr-10">
    //   <video
    //     className="absolute inset-0 w-full h-full object-cover"
    //     autoPlay
    //     loop
    //     muted
    //     playsInline
    //   >
    //     <source
    //       src="https://rttheme20.rtthemes.com/wp-content/uploads/sites/19/2016/01/dna-background-video.mp4"
    //       type="video/mp4"
    //     />
    //   </video>
    //   <div className="absolute inset-0 bg-gray-100 bg-opacity-90"></div>

    //   <div className="relative z-10 flex flex-col lg:flex-row items-center justify-center px-4 py-12">
    //     {/* Left Column */}
    //     <div className="lg:w-1/2 p-8">
    //       <div className="mb-6">
    //         <h4 className="text-2xl font-semibold">
    //           <span className="block text-base text-gray-600">Numbers Don't Lie</span>
    //           WHAT MAKES US SPECIAL?
    //         </h4>
    //       </div>
    //       <p className="mb-6 text-gray-700">
    //         Aenean at massa semper, posuere nisl eu, tempor lectus. Quisque bibendum metus in consequat lobortis. Duis volutpat sodales dolor et sagittis. Morbi purus velit, blandit quis auctor et, pellentesque ac leo.
    //       </p>
    //       <a
    //         href="#"
    //         className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
    //       >
    //         LEARN MORE
    //       </a>
    //     </div>

    //     {/* Right Columns */}
    //     <div className='w-1/2 flex flex-wrap rightdiv'> 
    //     <div className="lg:w-1/2 p-8 bg-gray-200 text-center">
    //       <div className="text-4xl font-bold text-gray-900">
    //         75
    //         </div>
    //       <div className="mt-2 text-gray-700">Professional Staff</div>
    //     </div>
    //     <div className="lg:w-1/2 p-8 bg-gray-200 text-center">
    //       <div className="text-4xl font-bold text-gray-900">35</div>
    //       <div className="mt-2 text-gray-700">Years of Experience</div>
    //     </div>
    //     <div className="lg:w-1/2 p-8 bg-gray-200 text-center">
    //       <div className="text-4xl font-bold text-gray-900">45</div>
    //       <div className="mt-2 text-gray-700">Rooms</div>
    //     </div>
    //     <div className="lg:w-1/2 p-8 bg-gray-200 text-center">
    //       <div className="text-4xl font-bold text-gray-900">5</div>
    //       <div className="mt-2 text-gray-700">Stars Comfort</div>
    //     </div>
    //     </div>
    //   </div>
    // </div>
    <div className="relative p-10">
        <video
        className="absolute inset-0 w-full h-full object-cover"
        autoPlay
        loop
        muted
        playsInline
      >
        <source
          src="https://rttheme20.rtthemes.com/wp-content/uploads/sites/19/2016/01/dna-background-video.mp4"
          type="video/mp4"
        />
      </video>
      <div className="absolute inset-0 bg-gray-100 bg-opacity-90"></div>
      {/* <div className="max-w-md mx-auto text-center">
        <h2 className="text-3xl font-bold text-gray-950 mb-5 mt-2">How we operate.</h2>
        <p className="text-base font-semibold text-gray-500">
          In samenwerking met experts uit diverse vakgebieden kunnen wij elke projecteis naadloos vervullen.
        </p>
      </div> */}

      <div data-aos="zoom-in"  className="max-w-5xl mx-auto rounded-2xl border border-gray-200 bg-gradient-to-r from-green-400 to-blue-500 mt-12">
        <div className="grid lg:grid-cols-5 gap-6 items-center">
          <div className="p-7 lg:col-span-3">
          <h4 className="text-2xl font-semibold text-white">
               <span className="block text-base text-white">Numbers Don't Lie</span>
                WHAT MAKES US SPECIAL?
             </h4>
            <p className="text-base font-medium text-white mt-5">
            Our results speak volumes: consistent turnaround, maximized revenue, and unbeatable collection ratios.
            </p>
            <p className="text-base font-medium text-white mt-2 mb-8">
            Proven success: shorter A/R cycles, significant revenue growth, and industry-leading collection rates.
            </p>
            {/* <a
              href="#"
              className="p-2 inline-flex items-center justify-center gap-2 rounded-md text-base font-semibold text-white bg-blue-500 hover:bg-blue-600 transition-all duration-500"
            >
              Contact Us <i className="ti ti-arrow-narrow-right text-3xl"></i>
            </a> */}
          </div>

          <div className="p-7 lg:col-span-2">
            <div className="grid sm:grid-cols-2 grid-cols-1 gap-4">
              <div className="p-5 lg:h-40 lg:w-40 flex items-center justify-center rounded-lg text-white bg-white/30 text-center">
                <div>
                  {/* <i className="ti ti-bulb text-5xl"></i> */}
                  <p className='text-3xl font-bold'>{turnaround}-14</p>
                  <h3 className="text-md font-bold mt-2">Days Turnaround Time</h3>
                </div>
              </div>

              <div className="p-5 lg:h-40 lg:w-40 flex items-center justify-center rounded-lg text-white bg-white/30 text-center">
                <div>
                  {/* <i className="ti ti-triangle-inverted text-5xl"></i> */}
                  <p className='text-3xl font-bold'>{reduction} %</p>
                  <h3 className="text-md font-bold mt-2">Reduction in A/R</h3>
                </div>
              </div>

              <div className="p-5 lg:h-40 lg:w-40 flex items-center justify-center rounded-lg text-white bg-white/30 text-center">
                <div>
                  {/* <i className="ti ti-device-imac text-5xl"></i> */}
                  <p className='text-3xl font-bold'>{revenue}-20 %</p>
                  <h3 className="text-md font-bold mt-2">Revenue Increase</h3>
                </div>
              </div>

              <div className="p-5 lg:h-40 lg:w-40 flex items-center justify-center rounded-lg text-white bg-white/30 text-center">
                <div>
                  {/* <i className="ti ti-ball-basketball text-5xl"></i> */}
                  <p className='text-3xl font-bold'>{ratio} %</p>
                  <h3 className="text-md font-bold mt-2">Collection Ratios</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
