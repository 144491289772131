import React from 'react';
// import emailjs from '@emailjs/browser';
import contact from '../assets/contact.jpg'
// import { toast } from 'react-toastify'
import Contactform from '../components/Contactform';
export default function Contact() {
  // const form = useRef();

  // const sendEmail = (e) => {
  //   e.preventDefault();

  //   console.log('working');

  //   if (form.current) {
  //     emailjs
  //       .sendForm('service_o5zrku8', 'template_5pw2l3n', form.current, 'Wh_3RSzZ2jVBcebrt') // Ensure publicKey is correct
  //       .then(
  //         (result) => {
  //           // console.log('SUCCESS!', form.value);
  //           toast('Message sent successfully!');
  //         },
  //         (error) => {
  //           console.log('FAILED...', error.text);
  //           toast('Failed to send message.');
  //         }
  //       );
  //   }
  // };




  return (
    <div className='border bg-gray-50'>
      <div className=' mt-20'>
        <img src={contact} alt="contact" className='w-full' style={{ height: '80vh' }} />
      </div>
      <div className="flex    flex-col items-center gap-10 pl-10 pr-10  pb-16 bg-gray-50">
        {/* <h1 className="text-3xl font-bold text-gray-800 mb-4 animate-fadeIn">
          Contact Us
        </h1> */}
        {/* <p className="text-lg text-gray-600 mb-8 animate-fadeIn">
          Right Medical Billing is the ideal financial partner for managing your
          revenue cycle. We are here to tailor our services to meet your needs.
          As your medical billing service provider, we are an extension of your
          staff. A part of your team. Your growth is our number one priority.
          All inquiries are returned within 24 hours.
          </p> */}
        {/* <div className="w-full max-w-4xl text-center border"> */}
        <div className="flex -mt-10  flex-col  md:flex-row md:gap-6 justify-around animate-fadeIn">
          {/* <div className="flex flex-col items-center mb-8 md:mb-0">
              <a
                href="tel:(281) 864-0448"
                className="text-blue-600 text-xl mb-2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
                </svg>
              </a>
              <h3 className="text-xl font-semibold text-gray-700">
                <a href="tel:(281) 864-0448">Call Us</a>
              </h3>
              <p className="text-gray-600">(281) 864-0448</p>
            </div> */}


          <div class="max-w-sm p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className='flex gap-4'>
              <svg
                aria-hidden="true"
                className="w-8 h-8"
                // fill="currentColor"
                style={{ fill: '#003680' }}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></path>
              </svg>
              {/* <a href="tel:+923048825798" */}
              <a href="tel:+1 908-270-2999"
                className="text-blue-600 text-xl mb-2">
                <h5 class="mb-2 text-2xl  tracking-tight text-[#003680] font-bold dark:text-white">Call US</h5>
              </a>
            </div>
            <p class="mb-3 font-bold text-gray-700  dark:text-gray-400">+1 908-270-2999</p>
            <a href="tel:+1 908-270-2999" class="capitalize inline-flex font-medium items-center text-blue-600 hover:underline">
              Click to make a call
              <svg class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
              </svg>
            </a>
          </div>

          {/* <div className="flex flex-col items-center mb-8 md:mb-0">
              <a
                href="mailto:sales@rightmedicalbilling.com"
                className="text-blue-600 text-xl mb-2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
                </svg>
              </a>
              <h3 className="text-xl font-semibold text-gray-700">
                <a href="mailto:sales@rightmedicalbilling.com">Email Us</a>
              </h3>
              <p className="text-gray-600">sales@rightmedicalbilling.com</p>
            </div> */}


          <div class="max-w-sm p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className='flex gap-4'>
              <svg
                aria-hidden="true"
                className="w-8 h-8"
                // fill="currentColor"
                style={{ fill: '#003680' }}
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path>
              </svg>
              <a href="mailto:info@thrivemedrcm.com">
                <h5 class="mb-2 text-2xl tracking-tight text-[#003680] font-bold dark:text-white">Email Us</h5>
              </a>
            </div>
            <p class="mb-3 font-bold text-gray-700 dark:text-gray-400">info@thrivemedrcm.com</p>
            <a href="mailto:info@thrivemedrcm.com" class="capitalize inline-flex font-medium items-center text-blue-600 hover:underline">
              Click here to email us
              <svg class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
              </svg>
            </a>
          </div>

          {/* <div className="flex flex-col items-center">
              <a
                href="https://www.google.com/maps/place/5530+Long+Prairie+Trace,+Richmond,+TX+77407"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 text-xl mb-2"
              >
                <svg
                  aria-hidden="true"
                  className="w-8 h-8"
                  fill="currentColor"
                  viewBox="0 0 384 512"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
                </svg>
              </a>
              <h3 className="text-xl font-semibold text-gray-700">
                <a
                  href="https://www.google.com/maps/place/5530+Long+Prairie+Trace,+Richmond,+TX+77407"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Address
                </a>
              </h3>
              <p className="text-gray-600">
                5530 Long Prairie Trace, Suite 600, Richmond, TX 77407
              </p>
            </div> */}


          <div class="max-w-sm p-6 mb-8 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <div className='flex gap-4'>
              <svg
                aria-hidden="true"
                className="w-8 h-8"
                // fill="#003680"
                style={{ fill: '#003680' }}
                viewBox="0 0 384 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"></path>
              </svg>
              <a href="#">
                <h5 class="mb-2 text-2xl tracking-tight text-[#003680] font-bold dark:text-white">Address</h5>
              </a>
            </div>
            <p class="mb-3 font-bold text-gray-700 dark:text-gray-400">13236 N 7th St Ste 4 Phoenix, AZ 85022</p>
            <a href="#" class="capitalize inline-flex font-medium items-center text-blue-600 hover:underline">
              Follow this To reach our location
              <svg class="w-3 h-3 ms-2.5 rtl:rotate-[270deg]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11v4.833A1.166 1.166 0 0 1 13.833 17H2.167A1.167 1.167 0 0 1 1 15.833V4.167A1.166 1.166 0 0 1 2.167 3h4.618m4.447-2H17v5.768M9.111 8.889l7.778-7.778" />
              </svg>
            </a>
          </div>

        </div>
      </div>

      {/* hhhhhhh */}
      <div className="flex  m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center gap-10 pl-10 pr-10  pb-16 bg-gray-50">
        <p className="text-4xl  bg-gradient-to-r from-green-400 to-blue-400 bg-clip-text text-transparent font-bold animate-fadeIn  ">
          Get In Touch
        </p>
        <p className="text-lg text-gray-600 mb-8 animate-fadeIn lg:text-lg xl:text-2xl md:text-xl sm:text-xl text-center">
          ThriveMed RCM is the ideal financial partner for managing your
          revenue cycle. We are here to tailor our services to meet your needs.
          As your medical billing service provider, we are an extension of your
          staff. A part of your team. Your growth is our number one priority.
          All inquiries are returned within 24 hours.
        </p>
      </div>
      {/* map form */}
      <div className="flex m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center  pl-8 pr-8  pb-16">
        <div
          class="grid md:grid-cols-2 gap-16 items-center relative overflow-hidden p-8 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-3xl max-w-10xl h-10vh  bg-white mt-4 font-[sans-serif] before:absolute before:right-0 before:w-[300px] before:bg-gradient-to-r from-green-400 to-blue-400 before:h-full max-md:before:hidden">
          
          {/* <div>
        

            <form ref={form} onSubmit={sendEmail} >
              <div class="space-y-4 mt-8">
                <input type="text" placeholder="Name" name='username'
                  class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" bg-gradient-to-r from-green-400 to-blue-400 />
                <input type="text" placeholder="Business Name" name='businessName'
                  class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />
                <div class="grid grid-cols-2 gap-6">
                  <input type="email" placeholder="Email" name='email'
                    class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />

                  <input type="tel" placeholder="Phone No." name='phoneNo'
                    class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />
                </div>
                <input type="number" placeholder="Monthly Billing" name='monthlyBilling'
                  class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />

                <input type="number" placeholder="No. of Providers" name='NoofProviders'
                  class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />
                <input type="number" placeholder="Total AR" name='totalAR'
                  class="px-2 py-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none" />

                <textarea placeholder="Your Message" name='message'
                  class="px-2 pt-3 bg-white w-full text-gray-800 text-sm border-b border-gray-300 focus:border-blue-600 outline-none"></textarea>
              </div>

              <button type="submit"
                // onClick={() => toast("Message Send Successfully!")}
                class="mt-8 flex items-center justify-center text-sm w-full rounded-md px-6 py-3 bg-gradient-to-r from-green-400 to-blue-400 hover:bg-blue-700 text-white">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='#fff' class="mr-2"
                  viewBox="0 0 548.244 548.244">
                  <path fill-rule="evenodd"
                    d="M392.19 156.054 211.268 281.667 22.032 218.58C8.823 214.168-.076 201.775 0 187.852c.077-13.923 9.078-26.24 22.338-30.498L506.15 1.549c11.5-3.697 24.123-.663 32.666 7.88 8.542 8.543 11.577 21.165 7.879 32.666L390.89 525.906c-4.258 13.26-16.575 22.261-30.498 22.338-13.923.076-26.316-8.823-30.728-22.032l-63.393-190.153z"
                    clip-rule="evenodd" data-original="#000000" />
                </svg>
                Send Message
              </button>
            </form>

            <ul class="mt-4 flex flex-wrap justify-center gap-6">
              <li class="flex items-center text-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='currentColor'
                  viewBox="0 0 479.058 479.058">
                  <path
                    d="M434.146 59.882H44.912C20.146 59.882 0 80.028 0 104.794v269.47c0 24.766 20.146 44.912 44.912 44.912h389.234c24.766 0 44.912-20.146 44.912-44.912v-269.47c0-24.766-20.146-44.912-44.912-44.912zm0 29.941c2.034 0 3.969.422 5.738 1.159L239.529 264.631 39.173 90.982a14.902 14.902 0 0 1 5.738-1.159zm0 299.411H44.912c-8.26 0-14.971-6.71-14.971-14.971V122.615l199.778 173.141c2.822 2.441 6.316 3.655 9.81 3.655s6.988-1.213 9.81-3.655l199.778-173.141v251.649c-.001 8.26-6.711 14.97-14.971 14.97z"
                    data-original="#000000" />
                </svg>
                <a href="javascript:void(0)" class="text-sm ml-4">
                  <strong>info@thrivemedrcm.com</strong>
                </a>
              </li>
              <li class="flex items-center text-blue-600">
                <svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" fill='currentColor'
                  viewBox="0 0 482.6 482.6">
                  <path
                    d="M98.339 320.8c47.6 56.9 104.9 101.7 170.3 133.4 24.9 11.8 58.2 25.8 95.3 28.2 2.3.1 4.5.2 6.8.2 24.9 0 44.9-8.6 61.2-26.3.1-.1.3-.3.4-.5 5.8-7 12.4-13.3 19.3-20 4.7-4.5 9.5-9.2 14.1-14 21.3-22.2 21.3-50.4-.2-71.9l-60.1-60.1c-10.2-10.6-22.4-16.2-35.2-16.2-12.8 0-25.1 5.6-35.6 16.1l-35.8 35.8c-3.3-1.9-6.7-3.6-9.9-5.2-4-2-7.7-3.9-11-6-32.6-20.7-62.2-47.7-90.5-82.4-14.3-18.1-23.9-33.3-30.6-48.8 9.4-8.5 18.2-17.4 26.7-26.1 3-3.1 6.1-6.2 9.2-9.3 10.8-10.8 16.6-23.3 16.6-36s-5.7-25.2-16.6-36l-29.8-29.8c-3.5-3.5-6.8-6.9-10.2-10.4-6.6-6.8-13.5-13.8-20.3-20.1-10.3-10.1-22.4-15.4-35.2-15.4-12.7 0-24.9 5.3-35.6 15.5l-37.4 37.4c-13.6 13.6-21.3 30.1-22.9 49.2-1.9 23.9 2.5 49.3 13.9 80 17.5 47.5 43.9 91.6 83.1 138.7zm-72.6-216.6c1.2-13.3 6.3-24.4 15.9-34l37.2-37.2c5.8-5.6 12.2-8.5 18.4-8.5 6.1 0 12.3 2.9 18 8.7 6.7 6.2 13 12.7 19.8 19.6 3.4 3.5 6.9 7 10.4 10.6l29.8 29.8c6.2 6.2 9.4 12.5 9.4 18.7s-3.2 12.5-9.4 18.7c-3.1 3.1-6.2 6.3-9.3 9.4-9.3 9.4-18 18.3-27.6 26.8l-.5.5c-8.3 8.3-7 16.2-5 22.2.1.3.2.5.3.8 7.7 18.5 18.4 36.1 35.1 57.1 30 37 61.6 65.7 96.4 87.8 4.3 2.8 8.9 5 13.2 7.2 4 2 7.7 3.9 11 6 .4.2.7.4 1.1.6 3.3 1.7 6.5 2.5 9.7 2.5 8 0 13.2-5.1 14.9-6.8l37.4-37.4c5.8-5.8 12.1-8.9 18.3-8.9 7.6 0 13.8 4.7 17.7 8.9l60.3 60.2c12 12 11.9 25-.3 37.7-4.2 4.5-8.6 8.8-13.3 13.3-7 6.8-14.3 13.8-20.9 21.7-11.5 12.4-25.2 18.2-42.9 18.2-1.7 0-3.5-.1-5.2-.2-32.8-2.1-63.3-14.9-86.2-25.8-62.2-30.1-116.8-72.8-162.1-127-37.3-44.9-62.4-86.7-79-131.5-10.3-27.5-14.2-49.6-12.6-69.7z"
                    data-original="#000000"></path>
                </svg>
                <a href="javascript:void(0)" class="text-sm ml-4">
                  <strong>+1 908-270-2999</strong>
                </a>
              </li>
            </ul>
          </div> */}

          <Contactform/>
          <div class="z-10 relative h-full max-md:min-h-[350px]">
            <iframe src="https://maps.google.com/maps?q=13236 N 7th St Ste 4 Phoenix, AZ 85022&t=&z=13&ie=UTF8&iwloc=&output=embed"
              class="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>


    </div>
    // </div>
  )
}
