import React from 'react'
import Contactform from '../components/Contactform';
import tickk from '../assets/subservices/tickk.png'
export default function RCM() {
  return (
    <div className='mt-20'>
      <div className="flex m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center  pl-8 pr-8  pb-16">
        <div
          class="grid md:mt-10 md:grid-cols-2 gap-16 items-center relative overflow-hidden p-8 shadow-[0_2px_10px_-3px_rgba(6,81,237,0.3)] rounded-3xl max-w-10xl h-10vh  bg-white mt-4 font-[sans-serif] before:absolute before:right-0 before:w-[300px]  before:h-full max-md:before:hidden">

          <div class=" relative h-full max-md:min-h-[350px]">
            {/* <iframe src="https://maps.google.com/maps?q=13236 N 7th St Ste 4 Phoenix, AZ 85022&t=&z=13&ie=UTF8&iwloc=&output=embed"
              class="left-0 top-0 h-full w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg" frameborder="0"
              allowfullscreen></iframe> */}
            <div className='flex flex-col'>
              <div class="text-start  mb-6">
                <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Synergize Your Revenue Cycle:</h2>
                <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Schedule a Demo with Us Today </h2>
                <p class="text-gray-600 mt-4 text-xl">
                  With ThriveMed, you can:
                  <ul className='mt-2'>
                    <li className='flex mt-2'><img src={tickk} className='w-6 h-5' alt="" />
                    Verify your patient's insurance eligibility prior to or at the time of service.
                    </li>
                    <li className='flex mt-2'><img src={tickk} className='w-6 h-5' alt="" />
                    Gain real-time insights into your financials, driving enhanced efficiency and growth for your practice.
                    </li>
                    <li className='flex mt-2'><img src={tickk} className='w-6 h-5' alt="" />
                    Stay on top of your claims with comprehensive tracking, optimizing the revenue cycle management process.
                    </li>
                    <li className='flex mt-2'><img src={tickk} className='w-6 h-5' alt="" />
                    Reduce denials, efficiently handle appeals, maximize reimbursements, enhance patient satisfaction, and elevate your brand’s reputation.
                    </li>
                  </ul>
                </p>
                <p class="text-gray-600 mt-6 text-xl">
                Partner with ThriveMed today - Leave us a message and we’ll get in touch shortly to schedule your personalized demo.
                </p>
              </div>


            </div>

          </div>
          <Contactform />
        </div>
      </div>
    </div>
  )
}
