import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import choose1 from '../assets/choose1.png'
import choose2 from '../assets/choose2.jpg'
import dots from '../assets/dots.png'
import aboutus from '../assets/aboutus.PNG'
import tickk from '../assets/subservices/tickk.png'
export default function Whychoose() {
  const [experience, setExperience] = useState(0);
  const [industry, setIndustry] = useState(0);
  const [projects, setProjects] = useState(0);


  useEffect(() => {
    AOS.init({ duration: 1000 });
    const ind = setInterval(() => {
      setIndustry(prev => {
        if (prev >= 200) {
          clearInterval(ind);
          return prev;
        }
        return prev + 1;
      });
    }, 50);
    const exp = setInterval(() => {
      setExperience(prev => {
        if (prev >= 20) {
          clearInterval(exp);
          return prev;
        }
        return prev + 1;
      });
    }, 50);
    const pro = setInterval(() => {
      setProjects(prev => {
        if (prev >= 200) {
          clearInterval(pro);
          return prev;
        }
        return prev + 1;
      });
    }, 50);
    return () => clearInterval(pro);
  }, []);


  // console.log(projects);


  return (
    <>

      {/* <div className="bg-white   overflow-hidden  flex justify-center items-center ">
      <div className='flex flex-col-reverse gap-20 justify-center  items-center  lg:flex-row-reverse lg:justify-between mt-10 mb-10  '>

        <div className='right pr-8 pl-8 mt-6 '>
          <img src={aboutus} alt="" className='mb-3' />
          <h2 className='text-3xl font-bold font-serif capitalize' data-aos="fade-down">Why Choose Heartland</h2>
          <h2 className='text-3xl font-bold font-serif' data-aos="zoom-in-left">RCM?</h2>
          <p className='mt-2 text-gray-500' data-aos="zoom-in-left">Our easy-to-use programming assists you with board training and</p>
          <p className='text-gray-500' data-aos="zoom-in-left">charging activities. examine patients. Earn compensation. It is</p>
          <p className='text-gray-500' data-aos="zoom-in-left">simply necessary.</p>
            <div className=" mx-auto px-4 mt-4" data-aos="zoom-in">
              <div className="w-full bg-[#087dce] text-white shadow-md rounded-lg">
                <div className="p-8">
                  <ul className="flex flex-wrap justify-start">
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Reasonable</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Innovative</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Convenient</span>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap justify-start">
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Transparent</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Ingenious</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Precise</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <div className='w-96 h-80'>
          <img src={choose1} data-aos="zoom-in" className='relative rounded-xl' alt="" />
          <img src={choose2} alt="" data-aos="zoom-in-up" className='absolute w-60  rounded-2xl -mt-28 -ml-14' />


        </div>
      </div>
    </div> */}

      <section className="py-16 w-full overflow-hidden ">
        <div className=" mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl p-4">
          <div className="flex flex-wrap -mx-4">

            <div className="w-full md:w-1/2 px-4 flex">
              <img
                src="https://demos.layoutdivi.com/wp-content/uploads/2022/11/Divi-About-Us-Section-Hero-Image-1.jpg"
                alt="Divi About Us Section Hero Image"
                className="w-full  mb-8"
              />
              <div className='bg-white h-fit pt-3 pl-4 pr-4 mt-auto mb-auto -ml-32'>
                <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
                  <p className="text-4xl text-brown-700">{industry}</p>
                  <h3 className="text-gray-600">Industrial Solutions</h3>
                </div>
                <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
                  <p className="text-4xl text-brown-700">{experience}</p>
                  <h3 className="text-gray-600">Years Experience</h3>
                </div>
                <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
                  <p className="text-4xl text-brown-700">{projects}</p>
                  <h3 className="text-gray-600">Projects Completed</h3>
                </div>
              </div>
            </div>

            <div className="w-full md:w-1/2 px-4">
              <div className="mb-8">
                <h2 className="text-3xl font-bold bg-gradient-to-r from-green-400 to-blue-400 bg-clip-text text-transparent border-b-2 border-orange-500 pb-2 inline-block">About Company</h2>
              </div>
              <div className="mb-8">
                <h2 className="text-2xl font-bold bg-gradient-to-r from-green-400 to-blue-400 bg-clip-text text-transparent mb-4">We’re More than a Medical Billing Company</h2>
                <p className="italic 2xl:text-2xl text-brown-700">There are many approaches to medical billing, but we focus on personalized, compliant, and efficient solutions that go beyond the basics.</p>
              </div>
              <div className="mb-8">
                <p className='2xl:text-2xl '>Each practice is unique, and we tailor our services to ensure the best possible financial outcomes for healthcare providers.</p>
              </div>
              <ul className='p-2 2xl:text-2xl '>
                <li className='flex p-2'><img src={tickk} className='w-6 h-5' alt="" />Partnering with Healthcare for Sustainable Growth</li>
                <li className='flex p-2'><img src={tickk} className='w-6 h-5' alt="" />Helping Practices Maximize Revenue</li>
                <li className='flex p-2'><img src={tickk} className='w-6 h-5' alt="" />Industry Leaders in Comprehensive Billing Solutions</li>
                <li className='flex p-2'><img src={tickk} className='w-6 h-5' alt="" />Dedicated to Simplifying Healthcare Finances Nationwide</li>
              </ul>
              <button> <a href="/Companyoverview" class="bg-gradient-to-r from-green-500 to-blue-700 text-white md:p-5 text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              More Details &rarr;
            </a>
            </button>
            </div>
          </div>
        </div>
      </section>


    </>
  );
};

const Blurb = ({ icon, title }) => {
  return (
    <div className="flex items-start mb-8">
      <span className="text-brown-700 mr-4 text-2xl">
        <i className={`fas fa-${icon}`}></i>
      </span>
      <h4 className="text-lg font-semibold">{title}</h4>
    </div>
  );
};


{/* </> */ }
//   )
// }
