// import React, { useEffect } from 'react';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import benefits from '../assets/benefits.jpg'
// import benefittick from '../assets/New folder/benefittick.PNG'

// export default function Benefits() {
//   useEffect(() => {
//     AOS.init({ duration: 1000 });
//   }, []);
//   return (
//     <div className="bg-[#1b1f2e] overflow-hidden  flex justify-center items-center">
//       <div className='flex flex-col p-3 justify-center  items-center lg:flex-row lg:justify-between border h-full '>
//         <div className='border  ' style={{height:'600px'}} >
//           <img src={benefits} data-aos="zoom-in" className='w-full' style={{height:'600px'}} alt="" />
//         </div>
//         <div className='right pr-8 pl-8  pt-6' >
//           <h2 className='text-lg font-bold font-sans mb-2 capitalize text-[#087dce]' data-aos="fade-down">WHY CHOOSE US?</h2>
//           <h2 className='text-3xl font-bold font-sans capitalize mb-2 text-white' data-aos="fade-down">Benefits of Working With HeartLand RCM</h2>
//           <p className='mt-2 text-gray-400' data-aos="zoom-in-left">We provide customized insurance & patient billing services, healthcare recovery assistance, and</p>
//           <p className='text-gray-400 mb-10' data-aos="zoom-in-left">practice management solutions to organizations of all types and sizes.</p>
//           <div className=" mx-auto  mt-4" >
//             <div className="w-full  text-white  rounded-lg">
//               <div className="flex gap-4 mb-4">
//                 <img src={benefittick} alt="benfittick" className='lg:w-28 lg:h-24 w-12 h-12 ' />
//                 <div className='inner' data-aos="fade-up">
//                   <p className='mb-2 text-xl font-bold'>Decrease Costs:</p>
//                   <p className='mb-2 text-gray-400'>By outsourcing medical billing services to HeartLand RCM, you will save thousands of dollars.</p>
//                 </div>
//               </div>
//               <div className="flex gap-4 mb-4">
//                 <img src={benefittick} alt="benfittick" className='lg:w-28 lg:h-24 w-12 h-12' />
//                 <div className='inner' data-aos="fade-up">
//                   <p className='mb-2 text-xl font-bold'>Decrease Costs:</p>
//                   <p className='mb-2 text-gray-400'>By outsourcing medical billing services to HeartLand RCM, you will save thousands of dollars.</p>
//                 </div>
//               </div>
//               <div className="flex gap-4 mb-4">
//                 <img src={benefittick} alt="benfittick" className='lg:w-28 lg:h-24 w-12 h-12' />
//                 <div className='inner' data-aos="fade-up">
//                   <p className='mb-2 text-xl font-bold'>Decrease Costs:</p>
//                   <p className='mb-2 text-gray-400'>By outsourcing medical billing services to HeartLand RCM, you will save thousands of dollars.</p>
//                 </div>
//               </div>
//             </div>
//           <button type="button" data-aos="zoom-out" className="text-white mb-4 border-white border bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
//             Learn More
//           </button>
//           </div>
//         </div>

//       </div>
//     </div>
//   )
// }


import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import benefits from '../assets/benefits.jpg';
import benefittick from '../assets/New folder/benefittick.PNG';
import icon1 from '../assets/New folder/icon1.PNG'
import icon2 from '../assets/New folder/icon2.PNG'
import icon3 from '../assets/New folder/icon3.PNG'
import icon4 from '../assets/New folder/icon4.PNG'
import icon5 from '../assets/New folder/icon5.PNG'
import icon6 from '../assets/New folder/icon6.PNG'
import icon7 from '../assets/New folder/icon7.PNG'
import icon8 from '../assets/New folder/icon8.PNG'
import icon9 from '../assets/New folder/icon9.PNG'
export default function Benefits() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const slides = [
    {
      title: "Allows More Focus on Patient Care",
      description: "We provide designs to fit any financial strategy. Heartland System uses the cloud to operate, saving your money. No compelling motive exists to invest money in servers.",
      icon: icon1,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
      number: "01",
    },
    {
      title: "Reduced Administrative Duties ",
      description: "The Heartland System is easy to use and doesn't require any training. Information about patients and organizations are easily accessible because of its attractive interface.",
      icon: icon2,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/closeup-hands-business-meeting.jpg",
      number: "02",
    },
    {
      title: "Enhances Cash Flow",
      description: "Take ownership of your trainings. Progressively introduce tolerable improvements. Run provides a breakdown of all of your financial assets.",
      icon: icon3,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "03",
    },
    {
      title: "Reduce Billing Errors",
      description: "Be careful where you receive your training. While seeing a patient, update your records with HeartlandRCM System Mobile to avoid returning to a PC.",
      icon: icon4,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
      number: "04",
    },
    {
      title: " Improve Patient Satisfaction",
      description: "It leverage advanced technology and streamlined processes to optimize revenue cycles, improve financial performance in healthcare organizations.",
      icon: icon5,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/bookkeeping-accounting-taxes-615384.jpg",
      number: "05",
    },
    {
      title: "Secure Patient Data",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon6,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
    {
      title: "Ensure Billing Compliance",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon7,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
  
  ];
  return (
    // <div className=" overflow-hidden flex justify-center items-center py-12">
    //   <div className="flex flex-col p-3 justify-center items-center lg:flex-row lg:justify-between max-w-7xl mx-auto">
    //     <div className='w-full lg:w-1/2 lg:h-[600px]'>
    //       <img src={benefits} data-aos="zoom-in" className='w-full h-full object-cover' alt="Benefits" />
    //     </div>
    //     <div className="right flex flex-col justify-between lg:w-1/2 w-full pr-8 pl-8 pt-6">
    //       <div>
    //         <h2 className="text-lg font-bold font-sans mb-2 capitalize text-[#087dce]" data-aos="fade-down">WHY CHOOSE US?</h2>
    //         <h2 className="text-3xl font-bold font-sans capitalize mb-2 text-gray-700" data-aos="fade-down">Benefits of Working With ThriveMed RCM</h2>
    //         <p className="mt-2 text-gray-400" data-aos="zoom-in-left">We provide customized insurance & patient billing services, healthcare recovery assistance, and</p>
    //         <p className="text-gray-400 mb-10" data-aos="zoom-in-left">practice management solutions to organizations of all types and sizes.</p>
    //       </div>
    //       <div className="mx-auto mt-4">
    //         <div className="w-full text-gray-700 rounded-lg">
    //           <div className="flex gap-4 mb-4">
    //             <img src={benefittick} alt="benefittick" className="lg:w-12 lg:h-12 w-12 h-12 object-contain" />
    //             <div className="inner" data-aos="fade-up">
    //               <p className="mb-2 text-xl font-bold">Decrease Costs:</p>
    //               <p className="mb-2 text-gray-400">By outsourcing medical billing services to HeartLand RCM, you will save thousands of dollars.</p>
    //             </div>
    //           </div>
    //           <div className="flex gap-4 mb-4">
    //             <img src={benefittick} alt="benefittick" className="lg:w-12 lg:h-12 w-12 h-12 object-contain" />
    //             <div className="inner" data-aos="fade-up">
    //               <p className="mb-2 text-xl font-bold">Get Paid Faster:</p>
    //               <p className="mb-2 text-gray-400">We provide reliable electronic medical billing services to ensure that claims are being submitted to all possible carriers, and we are set up to receive ERAs, further reducing turnaround time.</p>
    //             </div>
    //           </div>
    //           <div className="flex gap-4 mb-4">
    //             <img src={benefittick} alt="benefittick" className="lg:w-12 lg:h-12 w-12 h-12 object-contain" />
    //             <div className="inner" data-aos="fade-up">
    //               <p className="mb-2 text-xl font-bold">Increase Revenue:</p>
    //               <p className="mb-2 text-gray-400">This has the net effect of reducing the number of rejected or denied claims due to an inefficient medical billing cycle.</p>
    //             </div>
    //           </div>
    //         </div>
    //         <button type="button" data-aos="zoom-out" className="text-white mb-4 border-white border bg-gradient-to-r from-green-400 to-blue-400 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
    //           Learn More
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="  flex mb-12  flex-col  items-center animate-zoomInUp" data-aos="zoomInUp">
      <div className="flex lg:flex-row sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl  lg:pl-10 lg:pr-10 bg-gradient-to-r from-green-400 to-blue-400 p-4 pb-20  w-3/4 flex-col gap-6 justify-between items-center bg-white  rounded-md shadow-lg">
        <div className="flex flex-col items-center lg:w-1/2 mt-8 mb-8">
          <h2 className="text-4xl text-white font-bold text-start animate-fadeIn" data-aos="fadeIn" data-aos-delay="200">
            Benefits of Choosing Our Medical Billing Services
          </h2>
          <p className="text-start text-white mt-4 animate-fadeIn" data-aos="fadeIn" data-aos-delay="300">
            ThriveMed RCM will not only take care of your financial burdens, but we, as a company, will strive to improve your overall RCM operations. This is our promise to all of our <a href="/#" className="text-[#003680] font-semibold">medical billing clients across the US</a>. Just as you are the best at what you do, we are the best at what we do. If you succeed, we succeed.
          </p>
          <p className="text-start text-white mt-4 animate-fadeIn" data-aos="fadeIn" data-aos-delay="400">
            Below are some of the reasons as to why many of our clients decided to choose us over our competitors.
          </p>
        </div>
        <div className="flex justify-center  w-full lg:w-1/2 2xl:w-1/3 2xl:mt-8 items-center animate-fadeInUp" data-aos="fadeInUp" data-aos-delay="200">
          <img
            src="https://rightmedicalbilling.com/wp-content/uploads/2023/09/benefits-section.webp"
            alt="Benefits section"
            className="rounded-xl shadow-lg h-80 w-full"

            srcSet="https://rightmedicalbilling.com/wp-content/uploads/2023/09/benefits-section.webp 1000w, https://rightmedicalbilling.com/wp-content/uploads/2023/09/benefits-section-300x200.webp 300w, https://rightmedicalbilling.com/wp-content/uploads/2023/09/benefits-section-768x512.webp 768w"
          />
        </div>
      </div>
      <div data-aos="fade-up"  className=' sm:max-w-md  sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-lg 2xl:max-w-screen-2xl lg:text-xl lg:p-10 p-5 lg:pl-12 -mt-20 lg:pr-12 2xl:pl-10 2xl:pr-10 flex gap-6 flex-wrap justify-center 2xl:justify-between'>
        {slides.map((slide, index) => (
          <div class="block max-w-[18rem]  2xl:max-w-md  p-6 w-full  bg-white border hover:text-white hover:bg-gradient-to-r from-green-400 to-blue-400   rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <div className=" text-center mb-5">
                    {/* <span className="" data-aos="zoom-in"> */}
                      <img src={slide.icon} alt="" data-aos="zoom-in" className='m-auto'/>
                    {/* </span> */}
                  </div>
            <h5 class="mb-2 text-xl uppercase font-bold tracking-tight dark:text-white text-center" data-aos="fade-up">{slide.title}</h5>
          </div>

        ))}

      </div>
    </div>
  );
}
