import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import features from '../assets/features.PNG'
import precise from '../assets/iconsswiper/precise.PNG'
import convenient from '../assets/iconsswiper/convenient.PNG'
import ingenious from '../assets/iconsswiper/ingenious.PNG'
import reasonable from '../assets/iconsswiper/reasonable.PNG'
import innovatuve from '../assets/iconsswiper/innovatuve.PNG'
export default function Whatwedo() {
  const slides = [
    {
      title: "Reasonable",
      description: "We provide designs to fit any financial strategy. Thrivemed System uses the cloud to operate, saving your money. No compelling motive exists to invest money in servers.",
      icon: reasonable,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
    },
    {
      title: "Precise",
      description: "The Thrivemed System is easy to use and doesn't require any training. Information about patients and organizations are easily accessible because of its attractive interface.",
      icon: precise,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/closeup-hands-business-meeting.jpg",
    },
    {
      title: "Ingenious",
      description: "Take ownership of your trainings. Progressively introduce tolerable improvements. Run provides a breakdown of all of your financial assets.",
      icon: ingenious,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
    },
    {
      title: "Convenient",
      description: "Be careful where you receive your training. While seeing a patient, update your records with HeartlandRCM System Mobile to avoid returning to a PC.",
      icon: convenient,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
    },
    {
      title: "Innovative",
      description: "It leverage advanced technology and streamlined processes to optimize revenue cycles, improve financial performance in healthcare organizations.",
      icon: innovatuve,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/bookkeeping-accounting-taxes-615384.jpg",
    },
    {
      title: "Transparent",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: reasonable,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
    },
  ];
  return (
    <section className="w-full py-8 2xl:pl-10 2xl:pr-10 pl-10 pr-10 sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl bg-white">
      <div className="text-center mb-6 ">
        <img src={features} className='m-auto' alt="" />
        {/* <span className="text-lg text-gray-600">What we do</span> */}
        <h2 className="text-4xl bg-gradient-to-r from-green-400 to-blue-400 bg-clip-text text-transparent font-bold" data-aos="zoom-in">Why Choose Us</h2>
      </div>
      <div className='lg:p-10 p-5 lg:pl-12 lg:pr-12 '>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          // breakpoints={{
          //   640: { slidesPerView: 2 },
          //   768: { slidesPerView: 2 },
          //   1024: { slidesPerView: 2 },
          // }}
          breakpoints={{
            360: { slidesPerView: 1, spaceBetween: 20 },
            375: { slidesPerView: 1, spaceBetween: 20 },
            390: { slidesPerView: 1, spaceBetween: 20 },
            412: { slidesPerView: 1, spaceBetween: 20 },
            414: { slidesPerView: 1, spaceBetween: 20 },
            430: { slidesPerView: 1, spaceBetween: 20 },
            640: { slidesPerView: 1, spaceBetween: 20 },
            768: { slidesPerView: 2, spaceBetween: 30 },
            1024: { slidesPerView: 2, spaceBetween: 30 },
            1280: { slidesPerView: 3, spaceBetween: 20 },
            1536: { slidesPerView: 3, spaceBetween: 10 },
            2000: { slidesPerView: 3, spaceBetween: 5 },
            2700: { slidesPerView: 3, spaceBetween: 5 },
          }}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        // navigation={true}
        // pagination={{ clickable: true }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="">

              {/* <div className="hipa-container">
                <div className="bg-white text-gray-700 p-12 lg:p-20  hover-effect">
                  <img src={slide.icon} className="m-auto  mb-5" data-aos="zoom-in" />
                  <p className="font-bold text-xl text-center mb-5" data-aos="fade-up">{slide.title}</p>
                  <div className="desc text-center" data-aos="fade-up">
                    {slide.description}
                  </div>
                  <div className="arrow text-center">
                    <span className="icon" data-aos="zoom-in">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div> */}
              {/* <div class="relative flex min-h-screen flex-col justify-center overflow-hidden bg-gray-50 py-6 sm:py-12"> */}
                <div
                  class="group border relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8  ring-1 ring-gray-900/5 transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl sm:mx-auto sm:max-w-sm sm:rounded-lg sm:px-10">
                  <span class="absolute top-8 left-8 z-0 h-20 w-20 rounded-full bg-gradient-to-r from-green-400 to-blue-400 transition-all duration-300 group-hover:scale-[10]"></span>
                  <div class="relative z-10 mx-auto max-w-md">
                    {/* <span class="grid h-20 w-20 place-items-center rounded-full bg-sky-500 transition-all duration-300 group-hover:bg-sky-400">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10 text-white transition-all">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                      </svg>
                    </span> */}
                     <img src={slide.icon} className="fill-white w-16 h-16 m-left" />
                     <div
                      class="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                      <p className='font-bold text-2xl'>{slide.title}</p>
                    </div>
                    <div
                      class="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300 group-hover:text-white/90">
                      <p>{slide.description}</p>
                    </div>
                    {/* <div class="pt-5 text-base font-semibold leading-7">
                      <p>
                        <a href="#" class="text-sky-500 transition-all duration-300 group-hover:text-white">Read the docs
                          &rarr;
                        </a>
                      </p>
                    </div> */}
                  </div>
                </div>
              {/* </div> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
