import React, { useEffect } from 'react'
import '../SCSS/style.scss';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import s1 from '../assets/services/s1.PNG'
import s2 from '../assets/services/s2.PNG'
import s3 from '../assets/services/s3.PNG'
import s4 from '../assets/services/s4.PNG'
import s5 from '../assets/services/s5.PNG'
import s6 from '../assets/services/s6.PNG'
import s7 from '../assets/services/s7.PNG'
import s8 from '../assets/services/s8.PNG'
export default function Subservice() {
  useEffect(() => {
    AOS.init();
  }, [])
  const services = [
    {
      img: s1,
      title: 'Hospital Billing Services',
      path: '/HospitalBillingServices'
    },
    {
      img: s2,
      title: 'Physician Billing Services',
      path: '/PhysicianBillingServices'
    },
    {
      img: s3,
      title: 'Laboratory Billing Services',
      path: '/LaboratoryBillingServices'
    },
    {
      img: s4,
      title: 'Imaging Center Services',
      path: '/ImagingCenterBilling'
    },
    {
      img: s5,
      title: 'Coverage Discovery',
      path: '/CoverageDiscovery'
    },
    {
      img: s6,
      title: 'AR Recovery Services',
      path: '/ARRecoveryServices'
    },
    {
      img: s7,
      title: 'Ambulatory Surgery Billing',
      path: '/AmbulatorySurgeryBilling'
    },
    {
      img: s8,
      title: 'Medical Billing Services',
      path: '/MedicalBillingServices'
    }
  ]
  return (
    <section>
      <p className='text-6xl font-extrabold uppercase text-center bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent' data-aos="zoom-in">Services</p>
      <div className="container">
        {
          services.map((service) => {
            return (<div className="cardd" data-aos="zoom-in-left">
              <div className="card-inner" style={{ '--clr': '#fff' }}>
                <div className="box">
                  <div className="imgBox">
                    <img
                      src={service.img}
                      alt="Matterhorn"
                    />
                    <p className='font-bold text-xl text-gray-600 text-wrap p-2 pl-6 pr-8'>{service.title}</p>
                  </div>
                  <div className="icon  ">
                    <Link to={service.path} className="iconBox bg-gradient-to-r from-green-400 to-blue-700 borde">
                      <span className="material-symbols-outlined">arrow_outward</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            )
          })
        }


        {/* <div className="card">
          <div className="card-inner" style={{ '--clr': '#fff' }}>
            <div className="box">
              <div className="imgBox">
                <img
                  src="https://plus.unsplash.com/premium_photo-1661964177687-57387c2cbd14?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8TW91bnQlMjBGdWppfGVufDB8fDB8fHww"
                  alt="Mount Fuji"
                />
              </div>
              <div className="icon">
                <a href="#" className="iconBox">
                  <span className="material-symbols-outlined">arrow_outward</span>
                </a>
              </div>
            </div>
          </div>
          <div className="content">
            <h3>Mount Fuji</h3>
            <p>
              Mount Fuji is an active volcano located on the Japanese island of
              Honshu, with a summit elevation of 3,776.24 m
            </p>
          </div>
        </div>

        <div className="card">
          <div className="card-inner" style={{ '--clr': '#fff' }}>
            <div className="box">
              <div className="imgBox">
                <img
                  src="https://images.unsplash.com/photo-1676471049029-f93852da351d?q=80&w=2762&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  alt="Mount Everest"
                />
              </div>
              <div className="icon">
                <a href="#" className="iconBox">
                  <span className="material-symbols-outlined">arrow_outward</span>
                </a>
              </div>
            </div>
          </div>
          <div className="content">
            <h3>Mount Everest</h3>
            <p>
              Mount Everest is Earth's highest mountain above sea level, located
              in the Mahalangur Himal sub-range of the Himalayas.
            </p>
          </div>
        </div> */}

      </div>
    </section>
  );
}
