import React, { useEffect, useRef } from 'react';
import './Carousal.css';
import { useNavigate } from 'react-router-dom';
import mh1 from '../assets/mh1.jpg'
import mh2 from '../assets/mh2.jpg'
import mh3 from '../assets/mh3.jpg'
import mh4 from '../assets/mh4.jpg'
import mh5 from '../assets/mh5.jpg'
import mg from '../assets/mg.jpg'
import heronew from '../assets/heronew.jpg'
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Carousel() {
  let navigate=useNavigate();
  useEffect(() => {
    AOS.init();
  }, [])
  const carouselRef = useRef(null);
  const nextRef = useRef(null);
  const prevRef = useRef(null);
  const timeRunning = 3000;
  const timeAutoNext = 7000;

  useEffect(() => {
    const carouselDom = carouselRef.current;
    const nextDom = nextRef.current;
    const prevDom = prevRef.current;

    const SliderDom = carouselDom.querySelector('.carousel .list');
    // const thumbnailBorderDom = carouselDom.querySelector('.carousel .thumbnail');
    // const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');
    const timeDom = carouselDom.querySelector('.carousel .time');

    // thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);

    const showSlider = (type) => {
      const SliderItemsDom = SliderDom.querySelectorAll('.carousel .list .item');
      // const thumbnailItemsDom = thumbnailBorderDom.querySelectorAll('.item');

      if (type === 'next') {
        SliderDom.appendChild(SliderItemsDom[0]);
        // thumbnailBorderDom.appendChild(thumbnailItemsDom[0]);
        carouselDom.classList.add('next');
      } else {
        SliderDom.prepend(SliderItemsDom[SliderItemsDom.length - 1]);
        // thumbnailBorderDom.prepend(thumbnailItemsDom[thumbnailItemsDom.length - 1]);
        carouselDom.classList.add('prev');
      }
      setTimeout(() => {
        carouselDom.classList.remove('next');
        carouselDom.classList.remove('prev');
      }, timeRunning);
    };

    nextDom.onclick = () => showSlider('next');
    prevDom.onclick = () => showSlider('prev');

    let runNextAuto = setTimeout(() => {
      nextDom.click();
    }, timeAutoNext);

    return () => {
      clearTimeout(runNextAuto);
      nextDom.onclick = null;
      prevDom.onclick = null;
    };
  }, []);

  const handleContact=()=>{
     navigate('/Contact');
  }

  return (
    <div ref={carouselRef} className=" carousel w-full overflow-hidden ">
      <div className="list ">
        <div className="item">
          <div className='back1'></div>
          {/* <img src={mh1} alt="Slider 1" loading='lazy' /> */}
         <div className='flex m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl'>
          <div className="content">
            <div className="author bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">ThriveMedRCM</div>
            <div className="title">We Take Care Of Your</div>
            <div className="topic">REVENUE</div>
            <div className="title">  While You On Your Patients.</div>
            {/* <div className="des">
               While You On Your Patients.
              </div> */}
            <div className="buttons">
              {/* <button>SEE MORE</button> */}
              <button
                type="button"
                className="text-white bg-gradient-to-r from-green-500 to-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleContact}
              >
               Contact Us
              </button>
            </div>
          </div>
          <div data-aos="fade-left" className="right lg:block hidden 2xl:mr-72 pr-10">
          <div class="group h-72 flex max-md:flex-col justify-center gap-2">
                <article class="group/article relative rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
                    <a class="absolute inset-0 text-white z-10" href="#0">
                        <span class="absolute inset-x-0 bottom-0 text-lg font-medium p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">"Innovate relentlessly. Stagnation is the enemy of progress."</span>
                    </a>
                    <img class="object-cover h-72 md:h-[480px] md:w-auto" src={heronew} width="960" height="480" alt="Image 01"/>
                </article>
                {/* <article class="group/article mt-10 relative rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
                    <a class="absolute inset-0 text-white z-10" href="#0">
                        <span class="absolute inset-x-0 bottom-0 text-lg font-medium p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">"Innovate relentlessly. Stagnation is the enemy of progress."</span>
                    </a>
                    <img class="object-cover  h-72 md:h-[480px] md:w-auto" src={mh3} width="960" height="480" alt="Image 01"/>
                </article> */}
                <article class="group/article mt-10 h-72 relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
                    <a class="absolute inset-0 text-white z-10" href="#0">
                        <span class="absolute inset-x-0 bottom-0 text-lg font-medium p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">"Embrace failure as a stepping stone to success."</span>
                    </a>                    
                    <img class="object-cover h-72 md:h-[480px] md:w-auto" src={mh3} width="960" height="480" alt="Image 02"/>
                </article>
                <article class="group/article relative w-full rounded-xl overflow-hidden md:group-hover:[&:not(:hover)]:w-[20%] md:group-focus-within:[&:not(:focus-within):not(:hover)]:w-[20%] transition-all duration-300 ease-[cubic-bezier(.5,.85,.25,1.15)] before:absolute before:inset-x-0 before:bottom-0 before:h-1/3 before:bg-gradient-to-t before:from-black/50 before:transition-opacity md:before:opacity-0 md:hover:before:opacity-100 focus-within:before:opacity-100 after:opacity-0 md:group-hover:[&:not(:hover)]:after:opacity-100 md:group-focus-within:[&:not(:focus-within):not(:hover)]:after:opacity-100 after:absolute after:inset-0 after:bg-white/30 after:backdrop-blur after:transition-all focus-within:ring focus-within:ring-indigo-300">
                    <a class="absolute inset-0 text-white z-10" href="#0">
                        <span class="absolute inset-x-0 bottom-0 text-lg font-medium p-6 md:px-12 md:py-8 md:whitespace-nowrap md:truncate md:opacity-0 group-hover/article:opacity-100 group-focus-within/article:opacity-100 md:translate-y-2 group-hover/article:translate-y-0 group-focus-within/article:translate-y-0 transition duration-200 ease-[cubic-bezier(.5,.85,.25,1.8)] group-hover/article:delay-300 group-focus-within/article:delay-300">"Dream big, start small, but most importantly, start."</span>
                    </a>                    
                    <img class="object-cover h-72 md:h-[480px] md:w-auto" src={mh4} width="960" height="480" alt="Image 03"/>
                </article>                                
           </div>
          </div>

          </div>
        </div>



        {/* <div className="item">
          <img src={mh2} alt="Slider 2"  loading='lazy' />
          <div className='flex justify-between'>
          <div className="content">
            <div className="author">ThriveMedRCM</div>
            <div className="title">We Take Care Of Your</div>
            <div className="topic">REVENUE</div>
            <div className="title">  While You On Your Patients.</div>
            <div className="buttons">
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
               Contact Us
              </button>
            </div>
          </div>
          <div className='text-3xl text-black'>helo world</div>
          </div>
        </div> */}
      </div>

      {/* <div className="thumbnail" data-aos="fade-left">
        <div className="item">
          <img src={mh1} alt="Thumbnail 1" />
         
        </div>
        <div className="item">
          <img src={mh2} alt="Thumbnail 2" />
         
        </div>
        <div className="item">
          <img src={mh3} alt="Thumbnail 3" />
       
        </div>
       
      </div> */}

      <div className="arrows">
        <button ref={prevRef} id="prev">	&#60;</button>
        <button ref={nextRef} id="next"><span>&#62;</span>
        </button>
      </div>

      <div className="time"></div>
    </div>

  );
}
