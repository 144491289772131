import React from 'react'
import coverage from '../assets/subservices/coveragee.webp'
import modern from '../assets/subservices/revenue.jpg'
import work from '../assets/subservices/work.webp'
import serve from '../assets/subservices/serve.jpg'
import tickk from '../assets/subservices/tickk.png'
import { useNavigate } from 'react-router-dom';
export default function CoverageDiscovery() {
  let navigate=useNavigate();
  const handlecontact=()=>{
    navigate('/Contact')
  }
  
  return (
    <div className='mt-20'>
      <div className='border overflow-hidden'>
        <div className='bg-fixed relative'
          style={{

            backgroundImage: `url(${coverage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '450px', // Adjust height as needed
            opacity: 0.6,
            width: '100vw',
            // overflow:'hidden'
          }}>
          {/* <img src={hero2} className='w-full '  alt="" /> */}
          <div className="absolute backdrop-blur-2xl w-[100%] mt-6 lg:mt-36 text-wrap  ">
            <h1 className='  text-center  font-bold text-2xl md:text-6xl text-[#003680] mb-2 '>Coverage Discovery Services
            </h1>
            <p className='  text-center   md:font-bold text-sm md:text-2xl mt-3 mb-3 text-wrap pl-12 pr-12 text-green-700 m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  '>
              A large portion of uncompensated care results from uninsured patients or missed billing opportunities. Up to 10% of written-off self-pay accounts could be covered by insurance or government programs, representing recoverable revenue.
            </p>
          </div>
        </div>
      </div>

      {/*  */}

      <div class="flex flex-col sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center py-12    lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Revenue Boost Through Coverage Discovery Services</h2>
            <p class="text-gray-600 mt-4">Coverage Discovery Services help identify hidden insurance coverage, ensuring that providers capture revenue previously lost due to missed billing opportunities. By uncovering eligible insurance for self-pay or underinsured patients, healthcare providers can significantly boost their revenue.</p>
          </div>

          <div class="flex flex-col md:flex-row    mb-8">
            <button class="bg-gradient-to-r from-green-400 to-blue-700 text-white text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              <a href="/Contact"> Contact Us</a>
            </button>
          </div>
        </div>

        <div class="relative">
          <img src={modern} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
        </div>


      </div>

      {/*  */}

      <p className="m-auto mb-4 sm:max-w-xl sm:text-2xl  md:max-w-full md:text-xl lg:max-w-screen-md lg:text-4xl text-center  bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent font-bold animate-fadeIn  pl-10 pr-10   ">
        Coverage Discovery Benefits Both Billing Teams and Front-End Staff
      </p>
      <p className='m-auto  text-center text-sm md:text-2xl lg:max-w-screen-lg  mb-3 text-wrap pl-12 pr-12 text-gray-500  '>
        Our services are highly valued by both front-end and billing staff, as they streamline workflows and improve efficiency. Additionally, they free up valuable time, allowing teams to focus on more critical tasks that demand their full attention.
      </p>

      {/*  */}
      <div class="flex flex-col sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row-reverse justify-between items-center py-12    lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">How ThriveMed’s Coverage Discovery Service Operates</h2>
            <p class="text-gray-600 mt-4">
              Manually researching potential payers for each patient would be impractical. Thrive Med's Coverage Discovery leverages specialized software tools to streamline this process. By automatically checking for coverage when a patient identifies as self-pay, we ensure that both patients and providers benefit from any available insurance. These tools feature advanced search capabilities, automated claim scrubbing, access to an extensive payer database, and sophisticated algorithms that minimize errors and false positives, ensuring accurate and efficient results.
            </p>
          </div>

          <div class="flex flex-col md:flex-row    mb-8">
            <button class="bg-gradient-to-r from-green-400 to-blue-700 text-white text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              <a href="/RCM"> Schedule a Demo</a>
            </button>
          </div>
        </div>

        <div class="relative">
          <img src={work} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
        </div>



      </div>
      {/* benefits block */}
      <div class="flex  flex-col  sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center  lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent mb-3">Benefits of Using
              Coverage Discovery</h2>
            <div className='flex flex-col lg:flex-row gap-5'>
              <ul>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Uncovers all possible coverage options to ensure maximum reimbursement</li>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Reduces Accounts Receivable Days</li>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Lowers the number of accounts sent to collections or written off</li>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Lowers collection costs for unpaid bills</li>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Minimizes overall bad debt</li>
                <li className='flex mb-2'><img src={tickk} className='w-6 h-5' alt="" />Enhances patient satisfaction</li>
              </ul>
            </div>
            {/* <p class="text-gray-600 mt-4">Thrive Med's advanced physician revenue cycle management system enhances every stage of the RCM process, from patient scheduling and insurance verification to payer credentialing and claims management. We are dedicated to improving your practice's financial stability while easing the administrative complexities of managing insurance interactions. Our comprehensive medical billing services cater to all specialties, offering a unified solution that simplifies your billing operations and optimizes revenue generation for your practice.</p> */}
          </div>
        </div>

        <div class="relative">
          <img src={serve} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
        </div>


      </div>
      {/*  */}
      <div className='bg-blue-950 p-5'>
        <div className='sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl  lg:text-xl  m-auto   flex   flex-col  lg:flex-row justify-center align-middle  p-6'>
          <div className='content    flex flex-col justify-center'>
            <p className='font-bold  text-4xl mb-4 text-center xl:text-4xl lg:text-start capitalize text-white'>A More Efficient Approach to Managing Your Billing</p>
          </div>
          <button
            // data-aos="zoom-in"
            type="button"
            className="text-white w-52 h-14 mt-4 mx-auto border-4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"

            onClick={handlecontact}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  )
}
