import React from 'react'
import coverage from '../assets/subservices/Aar.webp'
import modern from '../assets/subservices/dd.jpg'
import { useNavigate } from 'react-router-dom';
export default function ARRecoveryServices() {

  let navigate=useNavigate();
  const handlecontact=()=>{
    navigate('/Contact')
  }
  return (
    <div className='mt-20'>
      <div className='border overflow-hidden'>
        <div className='bg-fixed relative'
          style={{

            backgroundImage: `url(${coverage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '450px', // Adjust height as needed
            opacity: 0.6,
            width: '100vw',
            // overflow:'hidden'
          }}>
          {/* <img src={hero2} className='w-full '  alt="" /> */}
          <div className="absolute backdrop-blur-2xl w-[100%] mt-6 lg:mt-36 text-wrap  ">
            <h1 className='  text-center  font-bold text-2xl md:text-6xl text-[#003680] mb-2 '>Thrive Med's AR Recovery Services Optimize Revenue Collection
            </h1>
            <p className='  text-center   md:font-bold text-sm  mt-3 mb-3 text-wrap pl-12 pr-12 text-white m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-4xl  '>
              Thrive Med provides a full suite of AR management services, focusing on recovering revenue from aged accounts over 120 days. Our proven system excels at maximizing recovery, even for accounts previously considered uncollectible.
            </p>
          </div>
        </div>
      </div>

      {/*  */}


      <div class="flex m-auto flex-col sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-xl lg:text-xl lg:flex-row justify-between items-center py-12    lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Consistently boosting revenue through efficient claims management strategies</h2>
            <p class="text-gray-600 mt-4">
              ThriveMed's advanced system monitors each claim from start to finish, ensuring our clients receive the full revenue they’re entitled to.
            </p>
          </div>



          <div class="flex flex-col md:flex-row gap-6 mt-4   mb-8">
            <button> <a href="/Contact" class="bg-[#003680] md:rounded-xl w-full md:p-5 md:w-40 text-white text-center py-2  px-4  rounded hover:bg-[#1a365e] transition duration-300">
              Contact Us
            </a>
            </button>
            <button> <a href="/RCM" class="bg-[#37b822] text-white md:p-5 text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              Demo
            </a>
            </button>
          </div>
        </div>

        <div class="relative">
          <img src={modern} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
        </div>


      </div>


      {/*  */}
      <p className="m-auto mt-8 mb-4 sm:max-w-xl sm:text-2xl  md:max-w-full md:text-xl lg:max-w-screen-md lg:text-4xl text-center  bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent font-bold animate-fadeIn  pl-10 pr-10   ">
        Thrive Med’s Comprehensive 4-Step Approach to AR Recovery
      </p>
      <p className='m-auto  text-center text-sm md:text-2xl lg:max-w-screen-lg  mb-3 text-wrap pl-12 pr-12 text-gray-500  '>
        With years of expertise, our strategy for handling aging AR accounts is finely tuned. Our old AR recovery service offers a swift and efficient solution to recover outstanding revenue.
      </p>

      {/* time line */}

      <div class="m-auto mb-14 mt-14 sm:max-w-xl sm:text-2xl  md:max-w-full md:text-xl lg:max-w-screen-xl lg:text-2xl space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-0.5 before:bg-gradient-to-b before:from-transparent before:via-slate-300 before:to-transparent">


        <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">

          <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-gradient-to-r from-green-400 to-blue-700 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
              <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
            </svg>
          </div>

          <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow">
            <div class="flex items-center justify-between space-x-2 mb-1">
              <div class="font-bold text-3xl  bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Comprehensive Assessment</div>
              {/* <time class="font-caveat font-medium text-indigo-500">08/06/2023</time> */}
            </div>
            <div class="text-slate-500">Our AR team conducts a detailed review of all unresolved claims, ensuring every recoverable account is pursued, no matter the size.</div>
          </div>
        </div>


        <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">

          <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-gradient-to-r from-green-400 to-blue-700 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
              <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
            </svg>
          </div>

          <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow">
            <div class="flex items-center justify-between space-x-2 mb-1">
              <div class="font-bold text-3xl bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Prioritization</div>
              {/* <time class="font-caveat font-medium text-indigo-500">09/06/2023</time> */}
            </div>
            <div class="text-slate-500">Our team prioritizes analyzing older unpaid claims to prevent expirations, using advanced tools to maximize revenue while you focus on your practice.</div>
          </div>
        </div>


        <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">

          <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-gradient-to-r from-green-400 to-blue-700 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
              <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
            </svg>
          </div>

          <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow">
            <div class="flex items-center justify-between space-x-2 mb-1">
              <div class="font-bold text-3xl bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Claim Adjustment and Resubmission</div>
              {/* <time class="font-caveat font-medium text-indigo-500">10/06/2023</time> */}
            </div>
            <div class="text-slate-500">Our dedicated team ensures no claim is rejected or underpaid due to lack of follow-up, making appeals and monitoring denials to eliminate recurring issues in your revenue cycle.</div>
          </div>
        </div>


        <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active">

          <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-gradient-to-r from-green-400 to-blue-700 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="10">
              <path fill-rule="nonzero" d="M10.422 1.257 4.655 7.025 2.553 4.923A.916.916 0 0 0 1.257 6.22l2.75 2.75a.916.916 0 0 0 1.296 0l6.415-6.416a.916.916 0 0 0-1.296-1.296Z" />
            </svg>
          </div>

          <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow">
            <div class="flex items-center justify-between space-x-2 mb-1">
              <div class="font-bold text-3xl bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Patient Engagement</div>
              {/* <time class="font-caveat font-medium text-indigo-500">12/06/2023</time> */}
            </div>
            <div class="text-slate-500">If the outstanding balance is patient responsibility, we provide clear reports and can manage direct patient interactions, ensuring they understand their charges and are more likely to pay.</div>
          </div>
        </div>


        {/* <div class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group">

          <div class="flex items-center justify-center w-10 h-10 rounded-full border border-white bg-slate-300 group-[.is-active]:bg-emerald-500 text-slate-500 group-[.is-active]:text-emerald-50 shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2">
            <svg class="fill-current" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
              <path d="M12 10v2H7V8.496a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5V12H0V4.496a.5.5 0 0 1 .206-.4l5.5-4a.5.5 0 0 1 .588 0l5.5 4a.5.5 0 0 1 .206.4V10Z" />
            </svg>
          </div>

          <div class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 rounded border border-slate-200 shadow">
            <div class="flex items-center justify-between space-x-2 mb-1">
              <div class="font-bold text-slate-900">Delivered</div>
              <time class="font-caveat font-medium text-amber-500">Exp. 12/08/2023</time>
            </div>
            <div class="text-slate-500">Pretium lectus quam id leo. Urna et pharetra aliquam vestibulum morbi blandit cursus risus.</div>
          </div>
        </div> */}

      </div>

      {/*  */}
      <div className='bg-blue-950 p-5'>
        <div className='sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl  lg:text-xl  m-auto   flex   flex-col  lg:flex-row justify-center align-middle  p-6'>
          <div className='content    flex flex-col justify-center'>
            <p className='font-bold  text-4xl mb-4 text-center xl:text-4xl lg:text-start capitalize text-white'>A More Efficient Approach to Managing Your Billing</p>
          </div>
          <button
            // data-aos="zoom-in"
            type="button"
            className="text-white w-52 h-14 mt-4 mx-auto border-4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"

            onClick={handlecontact}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  )
}
