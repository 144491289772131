import React from 'react'

export default function Companyoverview() {
  return (
    <section className=' py-24'>
      <div class="flex flex-col  sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center py-12    lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="text-6xl font-bold leading-tight text-[#003680]">Billing and  Payments. <br></br>Right On Time</h2>
            <p class="text-gray-600 mt-4">From the moment your patient walks in until you receive payment for the services rendered, our RCM services team supports your growth by focusing on problem areas in your revenue cycle management.</p>
          </div>


          <div class="flex flex-col md:flex-row gap-6   mb-8">
            <button> <a href="tel:+1 908-270-2999" class="bg-[#003680] md:rounded-xl w-full md:p-5 md:w-40 text-white text-center py-2  px-4  rounded hover:bg-[#1a365e] transition duration-300">
              Call Us
            </a>
            </button>
            <button> <a href="mailto:info@thrivemedrcm.com" class="bg-[#37b822] text-white md:p-5 text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              Leave A Message
            </a>
            </button>
          </div>
        </div>

        <div class="relative">
          <img src="https://rightmedicalbilling.com/wp-content/uploads/2023/09/about-intro.jpg" alt="About Image" class="w-full h-auto max-w-lg rounded-3xl shadow-lg" />


          {/* <div class="absolute top-0 left-0 p-4">
            <svg aria-hidden="true" class="w-8 h-8 text-red-600" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z"></path>
            </svg>
          </div> */}
        </div>


      </div>
      <div className="flex mt-6  m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center gap-10 pl-10 pr-10  pb-16">
        <p className="text-4xl text-center  bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent font-bold animate-fadeIn  ">
          Keystone of Your <br />
          Financial Performance
        </p>
        <p className="text-lg text-gray-600 mb-8 animate-fadeIn lg:text-lg xl:text-2xl md:text-xl sm:text-xl text-center">
          From the first patient interaction to the final payment, our RCM team enhances your financial health by identifying and addressing challenges in your revenue cycle, ensuring your practice operates smoothly and profitably.
        </p>
      </div>
      <div className="flex flex-col  gap-6 lg:flex-row items-center w-full sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-sm">
        {/* left side */}
        <div className="flex flex-col  border border-white items-center w-fit  py-8">
          <h2 className="text-3xl font-bold  fadeIn text-start bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">About Our Team</h2>
          <p className="mt-4 text-lg text-start fadeIn delay-100">
            At ThriveMed RCM, our team of dedicated professionals is committed to delivering exceptional medical billing and coding services. Our experts bring a deep understanding of the latest industry standards and a wealth of experience to every client engagement. We pride ourselves on our ability to navigate the complexities of healthcare billing with precision, ensuring that our clients receive accurate and timely support. With a team that is both highly skilled and deeply knowledgeable, we are dedicated to providing the highest level of service in the industry.
          </p>
          <p className="mt-4 text-lg text-start fadeIn delay-100">
            At ThriveMed , we prioritize accessibility, transparency, and open communication in every client interaction. We strive to build strong, lasting relationships by being readily available to address any questions or concerns. Our team of experts is not only highly knowledgeable but also dedicated to offering personalized support, ensuring that each client receives the attention and care they deserve
          </p>
        </div>
        {/* right side */}
        <div className="flex flex-wrap  flex-col justify-center w-full py-8">
          <div className="flex flex-col items-center  lg:w-full w-full md:w-1/2 lg:flex-row p-4 fadeInUp">
            <div className="text-white p-4 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                <path d="M18 13a3.987 3.987 0 0 0-2 7.444v2.833a.721.721 0 0 0 1.231.51l.769-.768.769.768a.721.721 0 0 0 1.231-.51v-2.833a3.987 3.987 0 0 0-2-7.444z"></path>
                <path d="M12 17a5.993 5.993 0 0 1 9-5.191v-6.809a5.006 5.006 0 0 0-5-5h-8a5.006 5.006 0 0 0-5 5v12a5.006 5.006 0 0 0 5 5h6v-.54a5.975 5.975 0 0 1-2-4.46zm-4-13h8a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm0 4h8a1 1 0 0 1 0 2h-8a1 1 0 0 1 0-2zm3 6h-3a1 1 0 0 1 0-2h3a1 1 0 0 1 0 2z"></path>
              </svg>
            </div>
            <div className='flex flex-col'>
              <h3 className="mt-4 text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Certified Coders</h3>
              <p className="mt-2 text-start">
                {/* Our team of AAPC certified coders and expert billers have the knowledge and experience to serve your facility with detailed and accurate work. With our extensive knowledge, we relay information to your team and train them in the best practices that will increase your revenue. */}
                Our team of AAPC certified coders and experienced billers bring a wealth of expertise to your facility, ensuring precise and thorough work. We leverage our deep understanding to educate your team on best practices that lead to enhanced revenue generation.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center  lg:w-full w-full md:w-1/2 lg:flex-row p-4 fadeInUp">
            <div className="text-white p-4 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                <path d="M9,12c-3.309,0-6-2.691-6-6S5.691,0,9,0s6,2.691,6,6-2.691,6-6,6Zm13.696,7.134l-.974-.562c.166-.497,.278-1.019,.278-1.572s-.111-1.075-.278-1.572l.974-.562c.478-.276,.642-.888,.366-1.366-.277-.479-.887-.644-1.366-.366l-.973,.562c-.705-.794-1.644-1.375-2.723-1.594v-1.101c0-.552-.448-1-1-1s-1,.448-1,1v1.101c-1.079,.22-2.018,.801-2.723,1.594l-.973-.562c-.48-.277-1.09-.113-1.366,.366-.276,.479-.112,1.09,.366,1.366l.974,.562c-.166,.497-.278,1.019-.278,1.572s.111,1.075,.278,1.572l-.974,.562c-.478,.276-.642,.888-.366,1.366,.186,.321,.521,.5,.867,.5,.169,0,.341-.043,.499-.134l.973-.562c.705,.794,1.644,1.375,2.723,1.594v1.101c0,.552,.448,1,1,1s1-.448,1-1v-1.101c1.079-.22,2.018-.801,2.723-1.594l.973,.562c.158,.091,.33,.134,.499,.134,.346,0,.682-.179,.867-.5,.276-.479,.112-1.09-.366-1.366Zm-5.696-.634c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5,.673,1.5,1.5-.673,1.5-1.5,1.5Zm-9-1.5c0-1.044,.187-2.043,.514-2.975C3.778,14.279,0,18.202,0,23c0,.552,.448,1,1,1H11.349c-2.041-1.65-3.349-4.171-3.349-7Z"></path>
              </svg>
            </div>
            <div className='flex flex-col'>
              <h3 className="mt-4 text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Experience</h3>
              <p className="mt-2 text-start">
                {/* Our team of AAPC certified coders and expert billers have the knowledge and experience to serve your facility with detailed and accurate work. With our extensive knowledge, we relay information to your team and train them in the best practices that will increase your revenue. */}
                With over 10 years of experience in the medical billing industry, we've established strong, enduring relationships with both insurance companies and our clients. Our aim is to be a recognized and trusted partner within the industry, allowing us to advocate effectively for our clients and achieve outstanding results. Unlike other billing companies, we maintain a consistent level of high-quality service, no matter how long we've been working together.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center  lg:w-full w-full md:w-1/2 lg:flex-row p-4 fadeInUp">
            <div className="text-white p-4 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                <path d="M24,12c0,6.62-5.38,12-12,12S0,18.62,0,12,5.38,0,12,0c.19,0,.38,0,.57,.01,.83,.04,1.47,.74,1.43,1.57-.04,.83-.72,1.45-1.57,1.43-.14,0-.29-.01-.43-.01C7.04,3,3,7.04,3,12s4.04,9,9,9,9-4.04,9-9c0-.14,0-.29-.01-.43-.04-.83,.6-1.53,1.43-1.57,.85-.03,1.53,.6,1.57,1.43,0,.19,.01,.38,.01,.57Zm-13.09-3.85c.8-.23,1.26-1.05,1.04-1.85s-1.06-1.26-1.85-1.04c-3,.85-5.09,3.62-5.09,6.74,0,3.86,3.14,7,7,7,3.12,0,5.89-2.09,6.74-5.09,.23-.8-.23-1.62-1.04-1.85-.8-.23-1.62,.23-1.85,1.04-.52,1.82-2.21,3.09-3.85,3.09-2.21,0-4-1.79-4-4,0-1.64,1.27-3.33,3.09-3.85Z"></path>
                <path d="M17,11c-.55,0-1,.45-1,1s.45,1,1,1h1c.55,0,1-.45,1-1s-.45-1-1-1H17Z"></path>
              </svg>
            </div>
            <div className='flex flex-col'>
              <h3 className="mt-4 text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Personalized Services</h3>
              <p className="mt-2 text-start">
                {/* Our team of AAPC certified coders and expert billers have the knowledge and experience to serve your facility with detailed and accurate work. With our extensive knowledge, we relay information to your team and train them in the best practices that will increase your revenue. */}
                Our team works closely together to deliver tailored services that meet each client’s unique needs. Recognizing the complexity and challenges of medical billing, we are committed to streamlining the process and securing prompt reimbursements for our clients.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center  lg:w-full w-full md:w-1/2 lg:flex-row p-4 fadeInUp">
            <div className="text-white p-4 rounded-full">
              <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
                <path d="M21.86,5.43c-1.14-2.25-3.73-3.14-6.02-2.05-.81,.38-1.55,.93-2.18,1.56-.63-.63-1.36-1.18-2.18-1.56-2.28-1.09-4.87-.19-6.02,2.05-1.32,2.61-.43,5.79,1.2,8.06,1.26,1.73,2.98,3.03,4.95,3.86-.15,.58-.34,1.15-.58,1.7l-.36,.89c-1.41-.1-3.08-.44-4.45-1.2-1.63-.89-2.86-2.17-3.25-3.5-.34-1.18-1.55-1.83-2.72-1.48-.93,.28-1.59,1.17-1.57,2.13,.02,1.23,.57,2.83,1.65,4.19,1.6,2.05,4.29,3.75,8.22,3.96v3c0,.41,.34,.75,.75,.75h4c.41,0,.75-.34,.75-.75v-3c3.93-.21,6.62-1.91,8.22-3.96,1.08-1.36,1.63-2.96,1.65-4.19,.02-.96-.64-1.85-1.57-2.13-1.17-.35-2.38,.3-2.72,1.48-.39,1.33-1.62,2.61-3.25,3.5-1.37,.75-3.04,1.1-4.45,1.2l-.36-.89c-.24-.56-.44-1.12-.58-1.7,1.97-.83,3.69-2.13,4.95-3.86,1.63-2.27,2.52-5.45,1.2-8.06Zm-10.08-.18c.81,.38,1.54,.94,2.19,1.59,.65-.65,1.38-1.2,2.19-1.59,1.47-.68,3.18-.3,3.82,1.07,.86,1.69-.07,4.27-1.29,6.01-1.17,1.63-2.7,2.84-4.44,3.57-1.73-.73-3.27-1.94-4.44-3.57-1.22-1.73-2.15-4.32-1.29-6.01,.64-1.37,2.34-1.75,3.82-1.07ZM12,17c1.66,0,3,1.34,3,3v1h-6v-1c0-1.66,1.34-3,3-3Z"></path>
              </svg>
            </div>
            <div className='flex flex-col'>
              <h3 className="mt-4 text-xl font-bold bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Up To Date</h3>
              <p className="mt-2 text-start">
                {/* Our team of AAPC certified coders and expert billers have the knowledge and experience to serve your facility with detailed and accurate work. With our extensive knowledge, we relay information to your team and train them in the best practices that will increase your revenue. */}
                Our team stays informed on the latest industry regulations and compliance updates to guarantee precise and effective billing. We collaborate with clients to understand their specific needs, allowing us to customize our services to best support their practice.
              </p>
            </div>
          </div>
          {/*
        <div className="flex flex-col items-center w-full md:w-1/2 lg:w-1/4 p-4 fadeInUp delay-100">
          <div className="bg-blue-500 text-white p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
              <path d="M9,12c-3.309,0-6-2.691-6-6S5.691,0,9,0s6,2.691,6,6-2.691,6-6,6Zm13.696,7.134l-.974-.562c.166-.497,.278-1.019,.278-1.572s-.111-1.075-.278-1.572l.974-.562c.478-.276,.642-.888,.366-1.366-.277-.479-.887-.644-1.366-.366l-.973,.562c-.705-.794-1.644-1.375-2.723-1.594v-1.101c0-.552-.448-1-1-1s-1,.448-1,1v1.101c-1.079,.22-2.018,.801-2.723,1.594l-.973-.562c-.48-.277-1.09-.113-1.366,.366-.276,.479-.112,1.09,.366,1.366l.974,.562c-.166,.497-.278,1.019-.278,1.572s.111,1.075,.278,1.572l-.974,.562c-.478,.276-.642,.888-.366,1.366,.186,.321,.521,.5,.867,.5,.169,0,.341-.043,.499-.134l.973-.562c.705,.794,1.644,1.375,2.723,1.594v1.101c0,.552,.448,1,1,1s1-.448,1-1v-1.101c1.079-.22,2.018-.801,2.723-1.594l.973,.562c.158,.091,.33,.134,.499,.134,.346,0,.682-.179,.867-.5,.276-.479,.112-1.09-.366-1.366Zm-5.696-.634c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5,.673,1.5,1.5-.673,1.5-1.5,1.5Zm-9-1.5c0-1.044,.187-2.043,.514-2.975C3.778,14.279,0,18.202,0,23c0,.552,.448,1,1,1H11.349c-2.041-1.65-3.349-4.171-3.349-7Z"></path>
            </svg>
          </div>
          <h3 className="mt-4 text-xl font-bold">Experience</h3>
          <p className="mt-2 text-center">
            With over 25 years in the medical billing industry, we have built long-lasting relationships with insurance companies and our clients. Our goal is to be well-known within the networks, which enables us to communicate our client’s needs and get them results. Unlike other billing companies, our quality of service does not diminish over time.
          </p>
        </div>

        <div className="flex flex-col items-center w-full md:w-1/2 lg:w-1/4 p-4 fadeInUp delay-200">
          <div className="bg-blue-500 text-white p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
              <path d="M24,12c0,6.62-5.38,12-12,12S0,18.62,0,12,5.38,0,12,0c.19,0,.38,0,.57,.01,.83,.04,1.47,.74,1.43,1.57-.04,.83-.72,1.45-1.57,1.43-.14,0-.29-.01-.43-.01C7.04,3,3,7.04,3,12s4.04,9,9,9,9-4.04,9-9c0-.14,0-.29-.01-.43-.04-.83,.6-1.53,1.43-1.57,.85-.03,1.53,.6,1.57,1.43,0,.19,.01,.38,.01,.57Zm-13.09-3.85c.8-.23,1.26-1.05,1.04-1.85s-1.06-1.26-1.85-1.04c-3,.85-5.09,3.62-5.09,6.74,0,3.86,3.14,7,7,7,3.12,0,5.89-2.09,6.74-5.09,.23-.8-.23-1.62-1.04-1.85-.8-.23-1.62,.23-1.85,1.04-.52,1.82-2.21,3.09-3.85,3.09-2.21,0-4-1.79-4-4,0-1.64,1.27-3.33,3.09-3.85Z"></path>
              <path d="M17,11c-.55,0-1,.45-1,1s.45,1,1,1h1c.55,0,1-.45,1-1s-.45-1-1-1H17Z"></path>
            </svg>
          </div>
          <h3 className="mt-4 text-xl font-bold">Latest Technology</h3>
          <p className="mt-2 text-center">
            We believe in using the most up-to-date technology to support our clients' needs. Our technology not only enables us to be more efficient but also helps us to provide transparency to our clients and ensure accuracy in our work.
          </p>
        </div>

        <div className="flex flex-col items-center w-full md:w-1/2 lg:w-1/4 p-4 fadeInUp delay-300">
          <div className="bg-blue-500 text-white p-4 rounded-full">
            <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40" viewBox="0 0 24 24">
              <path d="M21.86,5.43c-1.14-2.25-3.73-3.14-6.02-2.05-.81,.38-1.55,.93-2.18,1.56-.63-.63-1.36-1.18-2.18-1.56-2.28-1.09-4.87-.19-6.02,2.05-1.32,2.61-.43,5.79,1.2,8.06,1.26,1.73,2.98,3.03,4.95,3.86-.15,.58-.34,1.15-.58,1.7l-.36,.89c-1.41-.1-3.08-.44-4.45-1.2-1.63-.89-2.86-2.17-3.25-3.5-.34-1.18-1.55-1.83-2.72-1.48-.93,.28-1.59,1.17-1.57,2.13,.02,1.23,.57,2.83,1.65,4.19,1.6,2.05,4.29,3.75,8.22,3.96v3c0,.41,.34,.75,.75,.75h4c.41,0,.75-.34,.75-.75v-3c3.93-.21,6.62-1.91,8.22-3.96,1.08-1.36,1.63-2.96,1.65-4.19,.02-.96-.64-1.85-1.57-2.13-1.17-.35-2.38,.3-2.72,1.48-.39,1.33-1.62,2.61-3.25,3.5-1.37,.75-3.04,1.1-4.45,1.2l-.36-.89c-.24-.56-.44-1.12-.58-1.7,1.97-.83,3.69-2.13,4.95-3.86,1.63-2.27,2.52-5.45,1.2-8.06Zm-10.08-.18c.81,.38,1.54,.94,2.19,1.59,.65-.65,1.38-1.2,2.19-1.59,1.47-.68,3.18-.3,3.82,1.07,.86,1.69-.07,4.27-1.29,6.01-1.17,1.63-2.7,2.84-4.44,3.57-1.73-.73-3.27-1.94-4.44-3.57-1.22-1.73-2.15-4.32-1.29-6.01,.64-1.37,2.34-1.75,3.82-1.07ZM12,17c1.66,0,3,1.34,3,3v1h-6v-1c0-1.66,1.34-3,3-3Z"></path>
            </svg>
          </div>
          <h3 className="mt-4 text-xl font-bold">Customer Care</h3>
          <p className="mt-2 text-center">
            At Right Medical Billing, we value accessibility, transparency, and communication. We believe in establishing a strong relationship with our clients and being available to address their queries and concerns promptly. Our team members are highly knowledgeable and accessible and are dedicated to providing personalized attention to each of our clients.
          </p>
        </div> */}
        </div>
      </div>
    </section>
  )
}
