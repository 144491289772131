import React from 'react'
import speciality from '../assets/speciality.jpg'
import Subspeciality from '../components/Subspeciality'
export default function Specialities() {
  return (
    <div className='border overflow-hidden'>
      <div className='bg-fixed relative'
        style={{

          backgroundImage: `url(${speciality})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '500px', // Adjust height as needed
          opacity: 0.6,
          width:'100vw',
          // overflow:'hidden'
        }}>
        {/* <img src={hero2} className='w-full '  alt="" /> */}
        <div className="absolute backdrop-blur-md w-[100%] mt-52 ">
          <h1 className='  text-center  font-bold text-2xl md:text-6xl  text-white bg-clip-text text-transparent  '>OUR SPECIALITIES</h1>
          <p className='  text-center   md:font-bold text-sm md:text-2xl mt-3 mb-3 text-wrap   text-white bg-clip-text text-transparent  '>We Help at Every Step From Concept to Market.</p>
        </div>
      </div>

      <Subspeciality />
    </div>
  )
}
