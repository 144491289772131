import React from 'react'
import { useNavigate } from 'react-router-dom';
import physi from '../assets/subservices/physi.jpg'
import maximus from '../assets/subservices/maximus.webp'
import serve from '../assets/subservices/serve.jpg'
import denial from '../assets/subservices/denial.png'
import tickk from '../assets/subservices/tickk.png'
export default function PhysicianBillingServices() {
  let navigate = useNavigate();

  const handledemo = () => {
    navigate('/RCM')
  }

  const handlecontact = () => {
    navigate('/Contact')
  }
  return (
    <section className=' py-24'>
      <div class="flex flex-col  sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center py-12    lg:px-4">

        <div className='flex flex-col lg:w-1/2'>
          <div class="text-start  mb-6">
            <h2 class="lg:text-6xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Comprehensive Physician Billing Services for Practices of All Sizes</h2>
            <p class="text-gray-600 mt-4">Customized Revenue Cycle Management and Billing Solutions for Physician Practices of All Sizes. Whether you’re a single-provider practice or a large multi-specialty group, our services are tailored to help you streamline operations, reduce billing errors, and accelerate payment collection.</p>
          </div>


          <div class="flex flex-col md:flex-row    mb-8">
            <button
              onClick={handledemo}
              class="bg-gradient-to-r from-green-400 to-blue-700 text-white text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
              Schedule a Demo
            </button>
          </div>
        </div>

        <div class="relative">
          <img src={physi} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl shadow-lg" />
        </div>


      </div>
      <div className="flex mt-6  m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center gap-10 pl-10 pr-10  pb-16">
        <p className="text-4xl text-center  bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent font-bold animate-fadeIn  ">
          We Provide The Necessary Services to Optimize Claims, Prevent Rejections and Maximize Revenue
        </p>
        {/* maximus image block */}
        <div class="flex flex-col  sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row-reverse justify-between items-center py-12    lg:px-4">

          <div className='flex flex-col lg:w-1/2'>
            <div class="text-start  mb-6">
              <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Comprehensive Range of Revenue Cycle Management Services</h2>
              <p class="text-gray-600 mt-4">Thrive Med's advanced physician revenue cycle management system enhances every stage of the RCM process, from patient scheduling and insurance verification to payer credentialing and claims management. We are dedicated to improving your practice's financial stability while easing the administrative complexities of managing insurance interactions. Our comprehensive medical billing services cater to all specialties, offering a unified solution that simplifies your billing operations and optimizes revenue generation for your practice.</p>
            </div>


            {/* <div class="flex flex-col md:flex-row    mb-8">
              <button class="bg-gradient-to-r from-green-400 to-blue-700 text-white text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
                Schedule a Demo
              </button>
            </div> */}
          </div>

          <div class="relative">
            <img src={maximus} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
          </div>


        </div>
        {/* denial management */}
        <div class="flex flex-col sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center py-12    lg:px-4">

          <div className='flex flex-col lg:w-1/2'>
            <div class="text-start  mb-6">
              <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent">Denial and Rejection Management</h2>
              <p class="text-gray-600 mt-4">Physicians often face revenue loss due to errors in medical billing that lead to claim rejections or denials. Our exceptional physician billing services ensure that every claim is meticulously tracked, preventing any missed income due to overlooked claims. We generate detailed analytical reports that highlight potential bottlenecks in the claims process and pre-authorization, allowing you to address issues proactively and optimize your revenue stream.</p>
            </div>

            <div class="flex flex-col md:flex-row    mb-8">
              <button
                onClick={handlecontact}
                class="bg-gradient-to-r from-green-400 to-blue-700 text-white text-center md:rounded-xl py-2 px-4 rounded hover:bg-[#2d7223] transition duration-300">
                Contact Us
              </button>
            </div>
          </div>

          <div class="relative">
            <img src={denial} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
          </div>


        </div>
      </div>
      <div className="flex mt-1  m-auto sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-2xl  flex-col items-center gap-10 pl-10 pr-10  pb-16">

        {/* additional services block */}
        <div class="flex  flex-col  sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl lg:flex-row justify-between items-center  lg:px-4">

          <div className='flex flex-col lg:w-1/2'>
            <div class="text-start  mb-6">
              <h2 class="text-4xl font-bold leading-tight bg-gradient-to-r from-green-400 to-blue-700 bg-clip-text text-transparent mb-3">Additional Services Include</h2>
              <div className='flex flex-col lg:flex-row gap-5'>
                <ul>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Physicians Revenue Cycle Management</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Medical Practice Management Services</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Electronic Medical Record (EMR) Consultation</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Medical Transcription</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />EMR/Technology Integration</li>
                </ul>
                <ul>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Financial Analysis & Reporting</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Credentialing Services</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Physicians Account Management</li>
                  <li className='flex'><img src={tickk} className='w-6 h-5' alt="" />Physicians Practice Management Consulting</li>
                </ul>
              </div>
              {/* <p class="text-gray-600 mt-4">Thrive Med's advanced physician revenue cycle management system enhances every stage of the RCM process, from patient scheduling and insurance verification to payer credentialing and claims management. We are dedicated to improving your practice's financial stability while easing the administrative complexities of managing insurance interactions. Our comprehensive medical billing services cater to all specialties, offering a unified solution that simplifies your billing operations and optimizes revenue generation for your practice.</p> */}
            </div>
          </div>

          <div class="relative">
            <img src={serve} alt="About Image" class="w-full h-auto max-w-lg rounded-3xl" />
          </div>


        </div>

      </div>

    </section>
  )
}
