import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { Link, useLocation } from 'react-router-dom';
import medical from '../assets/subservices/medical.png';
import physician from '../assets/subservices/physician.png';
import AR from '../assets/subservices/AR.png';
import imaging from '../assets/subservices/imaging.png';
import ambulatory from '../assets/subservices/ambulatory.png';
import laboratory from '../assets/subservices/laboratory.png';
import hospital from '../assets/subservices/hospital.png';
import coverage from '../assets/subservices/coverage.png';


export default function Navbar() {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const location = useLocation();
  const isHomePage = location.pathname == '/' || location.pathname == '/Services' || location.pathname == '/Specialities' || location.pathname == '/HospitalBillingServices'
    || location.pathname == '/MedicalBillingServices' || location.pathname == '/AmbulatorySurgeryBilling' || location.pathname == '/Contact' || location.pathname == '/Companyoverview'
    || location.pathname == '/Ourspecialities' || location.pathname == '/PhysicianBillingServices'
    || location.pathname == '/LaboratoryBillingServices' || location.pathname == '/ImagingCenterBilling' || location.pathname == '/RCM'
    || location.pathname == '/ARRecoveryServices' || location.pathname == '/CoverageDiscovery'

  const navtextcolor = location.pathname == '/'

  const toggleSideMenu = () => {
    console.log(isSideMenuOpen);
    setIsSideMenuOpen(!isSideMenuOpen);
  }
  const toggleDropdown = () => {
    console.log(isDropdownOpen);
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className='sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-lg m-auto'>
      <nav className={`absolute w-full  z-20 top-0 start-0 dark:border-gray-600 ${isHomePage ? '' : 'bg-black dark:bg-gray-900'}`}>
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <Link to="/" className="flex items-center space-x-3 rtl:space-x-reverse">
            <img src={logo} className="h-16" alt="Flowbite Logo" />
          </Link>
          <div className="flex md:order-1 lg:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <Link to='/RCM'
              type="button"
              className="text-white hidden md:block bg-gradient-to-r from-green-500 to-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Schedule a Demo
            </Link>
            {/* hamburger here */}
            <button
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              type="button"
              data-drawer-target="drawer-body-scrolling"
              data-drawer-show="drawer-body-scrolling"
              data-drawer-body-scrolling="true"
              aria-controls="drawer-body-scrolling"
              onClick={toggleSideMenu}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div className="items-center justify-between hidden w-full md:flex  md:w-auto md:order-1" id="navbar-sticky">
            <ul className="flex flex-col lg:pl-0 md:pl-10 p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <Link
                  to="/"
                  className={`block py-2 px-3 ${navtextcolor ? 'text-gray-700' : 'text-gray-700'} bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500`}
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/Services"
                  onClick={toggleDropdown}

                  className={`block py-2 px-3 ${navtextcolor ? 'text-gray-700' : 'text-gray-700'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700 flex items-center`}
                >
                  Services
                  <svg
                    className="w-2.5 h-2.5 ml-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 10 6"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d={isDropdownOpen ? "M1 5l4-4 4 4" : "M1 1l4 4 4-4"}
                    />
                  </svg>
                </Link>
              </li>
              <li>
                <Link
                  to="/Specialities"
                  className={`block py-2 px-3 ${navtextcolor ? 'text-gray-700' : 'text-gray-700'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Specialities
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/RCM"
                  className={`block py-2 px-3 ${navtextcolor? 'text-gray-700' :'text-gray-700'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  RCM AUDIT
                </Link>
              </li> */}
              <li>
                <Link
                  to="/Companyoverview"
                  className={`block py-2 px-3 ${navtextcolor ? 'text-gray-700' : 'text-gray-700'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  CompanyOverview
                </Link>
              </li>
              <li>
                <Link
                  to="/Contact"
                  className={`block py-2 px-3 ${navtextcolor ? 'text-gray-700' : 'text-gray-700'} rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700`}
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>

      </nav>
      {/* drop down show */}
      <div id="" className={`${isDropdownOpen ? 'max-w-5xl ' : 'max-h-0'}   z-50 backdrop-blur-3xl  overflow-hidden hidden rounded-2xl md:block lg:mt-36  md:mt-40 lg:ml-56 xl:ml-96  transition-max-height absolute duration-300 ease-in-out border-gray-200 shadow-sm    dark:bg-gray-800 dark:border-gray-600`}>
        <div className="grid  px-4 py-5 mx-auto text-gray-900 dark:text-white sm:grid-cols-3 md:px-6">
          <ul>
            <li>
              <Link onClick={toggleDropdown} to="/MedicalBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={medical} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Medical Billing Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    We take a comprehensive, all-inclusive approach.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/PhysicianBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={physician} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Physician Billing Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Your dedication to your patients is of the utmost importance.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/ARRecoveryServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={AR} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">AR Recovery Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    To meet the unique needs of your organization.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/RCM" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={AR} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">RCM Audit Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    To meet the unique needs of your organization.
                  </span>
                </div>
              </Link>
            </li>

          </ul>
          <ul>

            <li>
              <Link onClick={toggleDropdown} to="/LaboratoryBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={laboratory} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Laboratory Billing Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Leverage experience and how to help chart a successful future.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/HospitalBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={hospital} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Hospital Billing Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    We take a comprehensive, all-inclusive approach.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/CoverageDiscovery" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={coverage} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Coverage Discovery</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    We will handle complicated coding for your imaging center.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/ImagingCenterBilling" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={imaging} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Imaging Center Billing</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    We will handle complicated coding for your imaging center.
                  </span>
                </div>
              </Link>
            </li>
          </ul>
          <ul>
            <li>
              <Link onClick={toggleDropdown} to="/AmbulatorySurgeryBilling" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={ambulatory} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Ambulatory Surgery Billing</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    We will handle complicated coding for your imaging center.
                  </span>
                </div>
              </Link>
            </li>
            {/* <li>
              <Link onClick={toggleDropdown} to="/LaboratoryBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
                <img src={laboratory} className='w-6 h-6' alt="" />
                <div>
                  <div className="font-semibold">Laboratory Billing Services</div>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Leverage experience and how to help chart a successful future.
                  </span>
                </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/HospitalBillingServices" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
              <img src={hospital} className='w-6 h-6' alt="" />
               <div>
                <div className="font-semibold">Hospital Billing Services</div>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  We take a comprehensive, all-inclusive approach.
                </span>
               </div>
              </Link>
            </li>
            <li>
              <Link onClick={toggleDropdown} to="/CoverageDiscovery" className="block p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 flex space-x-2">
              <img src={coverage} className='w-6 h-6' alt="" />
               <div>
                <div className="font-semibold">Coverage Discovery</div>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  We will handle complicated coding for your imaging center.
                </span>
               </div>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>


      {/* drawer show */}


      <div id="drawer-body-scrolling"
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-64 dark:bg-gray-800 ${isSideMenuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        tabindex="-1" aria-labelledby="drawer-body-scrolling-label">
        <img src={logo} alt="" />
        {/* <h5 id="drawer-body-scrolling-label" class="text-base font-semibold text-gray-500 uppercase dark:text-gray-400">Menu</h5> */}
        <button type="button"
          onClick={toggleSideMenu}
          data-drawer-hide="drawer-body-scrolling" aria-controls="drawer-body-scrolling" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" >
          <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
          </svg>
          <span class="sr-only">Close menu</span>
        </button>
        <div class="py-4 overflow-y-auto">
          <ul class="space-y-2 font-medium">
            <li>
              <Link to="/" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 22 21">
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
                <span class="ms-3">Home</span>
              </Link>
            </li>
            <li>

              <Link to='/Services'
                onClick={toggleDropdown}
                type="button" class="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 21">
                  <path d="M15 12a1 1 0 0 0 .962-.726l2-7A1 1 0 0 0 17 3H3.77L3.175.745A1 1 0 0 0 2.208 0H1a1 1 0 0 0 0 2h.438l.6 2.255v.019l2 7 .746 2.986A3 3 0 1 0 9 17a2.966 2.966 0 0 0-.184-1h2.368c-.118.32-.18.659-.184 1a3 3 0 1 0 3-3H6.78l-.5-2H15Z" />
                </svg>
                <span class="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap">Services</span>
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                </svg>
              </Link>
              <ul id="dropdown-example" class={`${isDropdownOpen ? 'max-w-5xl ' : 'max-h-0'}hidden py-2 space-y-2`}>
                <li>
                  <Link to="/HospitalBillingServices" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Hospital Billing</Link>
                </li>
                <li>
                  <Link to="/PhysicianBillingServices" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Physician Billing</Link>
                </li>
                <li>
                  <Link to="/LaboratoryBillingServices" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Laboratory Billing</Link>
                </li>
                <li>
                  <Link to="/ImagingCenterBilling" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Imaging Center</Link>
                </li>
                <li>
                  <Link to="/CoverageDiscovery" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Coverage Discovery</Link>
                </li>
                <li>
                  <Link to="/ARRecoveryServices" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">AR Recovery</Link>
                </li>
                <li>
                  <Link to="/AmbulatorySurgeryBilling" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Ambulatory Surgery</Link>
                </li>
                <li>
                  <Link to="/RCM" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">RCM/Audit</Link>
                </li>
                <li>
                  <Link to="/MedicalBillingServices" class="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700">Medical Billing</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/Specialities" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 18">
                  <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Specialities</span>
                {/* <span class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">Pro</span> */}
              </Link>
            </li>
            <li>
              <Link to="/Companyoverview" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="m17.418 3.623-.018-.008a6.713 6.713 0 0 0-2.4-.569V2h1a1 1 0 1 0 0-2h-2a1 1 0 0 0-1 1v2H9.89A6.977 6.977 0 0 1 12 8v5h-2V8A5 5 0 1 0 0 8v6a1 1 0 0 0 1 1h8v4a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1v-4h6a1 1 0 0 0 1-1V8a5 5 0 0 0-2.582-4.377ZM6 12H4a1 1 0 0 1 0-2h2a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Company Overview</span>
                {/* <span class="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span> */}
              </Link>
            </li>
            <li>
              <Link to="/Contact" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                  <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Contact</span>
              </Link>
            </li>
            <li>
              <Link to="/RCM" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20">
                  <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Demo</span>
              </Link>
            </li>
            {/* <li>
              <Link to="#" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 16">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Sign In</span>
              </Link>
            </li>
            <li>
              <Link to="#" class="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg class="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                  <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                  <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                </svg>
                <span class="flex-1 ms-3 whitespace-nowrap">Sign Up</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>


    </div >
  )
}

